import React, { useState, useEffect } from 'react';
import {
  HTMLContent,
  ReleaseTitle,
  ResourceContainer,
  ResourceTitle,
  SectionContainer,
  TabContainer,
  TabsContent,
  YearsContent,
  ReleaseStyle,
  TitleDate,
  Date,
  ContainerDate,
  ContainerLink,
  Link,
  GhostButton,
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  Arrow,
  FlexContainer,
  ResourcePropsContainer,
  ContainerEditor,
} from './LearningHub.styled';
import { Tabs } from '@/components/NewNewTabs';
import { Button, DatePicker, Icon, PDInput, Tiptap } from '@/components';
import { ButtonsContainer } from '@/common.styled';
import {
  ReleaseYears,
  ReleaseMonth,
  ReleaseResource,
  ReleaseApplication,
  Release,
  Application,
} from './models/LearningHub.model';
import { apiRequest, apiRoutes } from '../../services';
import useCSV from '../../components/DownloadCSV/index.js';
import Jwt from '../../utils/jwt';
import ModalNewRelease from './Components/ModalNewRelease.tsx';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { base64ToBlob } from '@/utils/blobUtils.js'; 
import ModalNewTabApp from './Components/ModalNewTabApp.tsx';

const LearningHub: React.FC = () => {
  const [activeYear, setActiveYear] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('');
  const [currentTabTitle, setCurrentTabTitle] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [releaseYears, setReleaseYears] = useState<ReleaseYears[]>([]);
  const [releaseMonth, setReleaseMonth] = useState<ReleaseMonth[]>([]);
  const [activeMonth, setActiveMonth] = useState<string>('');
  const [release, setRelease] = useState<Release[]>([]);
  const [apps, setApps] = useState<Application[]>([]);
  const [infoApp, setInfoApp] = useState<[]>([]);
  const [editedData, setEditedData] = useState<{ [key: number]: Release }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [showEditInfo, setShowEditInfo] = useState<boolean>(false);
  const { downloadCSV } = useCSV();
  const [newTabTitle, setNewTabTitle] = useState<string>('');
  const [showModalRelease, setShowModalRelease] = useState<boolean>(false);
  const [showModalTabApp, setShowModalTabApp] = useState<boolean>(false);
  const [selectRelease, setSelectRelease] = useState<string>('');
  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  useEffect(() => {
    // Only auto-open the first accordion if data is loaded and there is at least one release.
    if (!isLoading && release.length > 0) {
      const firstReleaseId = release[0].id;
      setSelectRelease(firstReleaseId);
      setOpenAccordions({ [firstReleaseId]: true });
    }
  }, [activeYear, activeMonth, release, isLoading]);

  useEffect(() => {
    const initializeData = async () => {
      await getYears();
      await getApplications();
    };
    initializeData();
    getEditors();
  }, []);
  
  useEffect(() => {
    if (activeYear !== '') {
      getReleaseMonth();
    }
  }, [activeYear]);
  
  useEffect(() => {
    setCurrentTabTitle(activeTab);
    getResources();
  }, [activeTab]);
  
  useEffect(() => {
    if (activeMonth !== '') {
      getRelease();
    }
  }, [activeMonth]);
  
  useEffect(() => {
    if (release.length > 0 && activeTab) {
      getResources();
    }
  }, [release]);
  
  const handleSave = async () => {
    try {
      const updatePromises = Object.entries(editedData).map(
        ([releaseId, modifiedRelease]) => {
          const cleanedRelease = {
            ...modifiedRelease,
            // Process releaseResources as before and remove temporary IDs if needed.
            releaseResources: modifiedRelease.releaseResources
              ? modifiedRelease.releaseResources.map((resource) => {
                  if (typeof resource.id === 'string') {
                    const { id, ...restResource } = resource;
                    return restResource;
                  }
                  return resource;
                })
              : [],
            // We completely remove releaseApplications so no application data is saved.
          };
  
          return apiRequest({
            method: 'put',
            url: `${apiRoutes.releasedataUpdate}/${releaseId}`,
            data: cleanedRelease,
          });
        }
      );
  
      const results = await Promise.all(updatePromises);
  
      const errors = results.filter(([error]) => error);
      if (errors.length > 0) {
        console.error('Errores al guardar:', errors);
        return;
      }
  
      const updatedReleases = release.map((r) => {
        if (editedData[r.id]) {
          // Overwrite only the fields modified for the release; applications info is ignored.
          return {
            ...r,
            ...editedData[r.id],
          };
        }
        return r;
      });
  
      setRelease(updatedReleases);
      // Instead of filtering releases by their applications, simply update the infoApp state.
      setInfoApp(updatedReleases);
      setEditedData({});
      setIsEditing(false);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };
  
  const handleSaveApplications = async () => {
    try {
      // Process each modified application that has changes stored in editedData.
      const updatePromises = Object.entries(editedData).map(
        ([appId, modifiedApp]) => {
          const cleanedApp = {
            ...modifiedApp,
            // Update application resources to remove temporary IDs if present.
            resources: modifiedApp.resources
              ? modifiedApp.resources.map((resource) => {
                  // Remove temporary IDs (those that start with "temp-") before saving.
                  if (typeof resource.id === "string" && resource.id.startsWith("temp-")) {
                    const { id, ...restResource } = resource;
                    return restResource;
                  }
                  return resource;
                })
              : [],
          };
  
          return apiRequest({
            method: "put",
            url: `${apiRoutes.applications}/${appId}`,
            data: cleanedApp,
          });
        }
      );
  
      const results = await Promise.all(updatePromises);
  
      // If there are any errors while saving, log them.
      const errors = results.filter(([error]) => error);
      if (errors.length > 0) {
        console.error("Errors saving application changes:", errors);
        return;
      }
  
      // Merge updated application data into the apps state.
      const updatedApps = apps.map((app) => {
        if (editedData[app.id]) {
          return {
            ...app,
            ...editedData[app.id],
          };
        }
        return app;
      });
  
      setApps(updatedApps);
      setEditedData({});
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving application changes:", error);
    }
  };
  
  const handleCancel = () => {
    setIsEditing(false);
    setEditedData({});
  };
  

  
  const getYears = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: 'get',
      url: apiRoutes.years,
    });
    if (data) {
      setReleaseYears(data);
      setActiveYear(data[0].id);
      setIsLoading(false);
    }
    if (error) console.log('err', error);
  };
  
  const getReleaseMonth = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: 'get',
      url: `${apiRoutes.releaseYears}/${activeYear}`,
    });
  
    if (data) {
      setReleaseMonth(data);
      setActiveMonth(data?.[0]?.id);
      setIsLoading(false);
    }
    if (error) console.log('err', error);
  };
  
  const getRelease = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: 'get',
      url: `${apiRoutes.release}?monthId=${activeMonth}`,
    });
  
    if (data) {
      setRelease(data);
      setIsLoading(false);
    }
    if (error) console.log('err', error);
  };
  
  const getApplications = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: 'get',
      url: apiRoutes.applications,
    });
  
    if (data) {
      setApps(data);
      setActiveTab(data[0].id);
      getResources();
      setIsLoading(false);
    }
    if (error) console.log('err', error);
  };
  
  const getResources = async () => {
    const filteredReleases: any = release
      .map((release: any) => ({
        ...release,
        // Although application data exists in releases, we no longer save nor update them.
        releaseApplications: release.releaseApplications.filter(
          (app: any) => app.applicationId === activeTab
        ),
      }))
      .filter((release: any) => release.releaseApplications.length > 0);
  
    setInfoApp(filteredReleases);
  };
  
  const download = async (id) => {
    try {
      setLoadingButton(id);
      const [error, responseData] = await apiRequest({
        method: 'get',
        url: `${apiRoutes.downloadRelease}/${id}`,
      });
    
      if (error) {
        console.error('Error downloading release:', error);
        return;
      }
    
      const base64Data = responseData.file_data;
      const pdfBlob = base64ToBlob(base64Data, 'application/pdf');
      
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Release-${id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Error downloading release:', error);
    } finally {
      setLoadingButton(null);
     
    }
  };
  
  const downloadAppResource = async (resource) => {
    try {
      setLoadingButton(resource.id);
      const [error, responseData] = await apiRequest({
        method: 'get',
        url: `${apiRoutes.downloadAppResource}/${resource.id}`,
      });
    
      if (error) {
        console.error('Error downloading resource:', error);
        return;
      }
      
      const base64Data = responseData.file_data;
      const pdfBlob = base64ToBlob(base64Data, 'application/pdf');
      const url = window.URL.createObjectURL(pdfBlob);
    
      const link = document.createElement('a');
      link.href = url;
      link.download = resource.file_name || 'Resource.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Error downloading resource:', error);
    } finally {
      setLoadingButton(null);
    }
  };
  
  const getEditors = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: 'get',
      url: apiRoutes.editors,
    });
  
    if (data) {
      const token = Jwt.getItem('all');
      setShowEditInfo(data.some((user: string) => user.includes(token.sub)));
      setIsLoading(false);
    }
    if (error) console.log('err', error);
  };
  
  const handleAddResource = (releaseId: number) => {
    setEditedData((prev) => {
      const currentRelease =
        prev[releaseId] ||
        JSON.parse(JSON.stringify(release.find((r) => r.id === releaseId)));
  
      const newResource = {
        id: `temp-${crypto.randomUUID()}`,
        link: '',
        file_name: '',
        releaseId: releaseId,
      };
  
      return {
        ...prev,
        [releaseId]: {
          ...currentRelease,
          releaseResources: [...currentRelease.releaseResources, newResource],
        },
      };
    });
  };
  
  const handleAddAppResource = (appId: number) => {
    setEditedData((prev) => {
      const currentApp =
        prev[appId] ||
        JSON.parse(JSON.stringify(apps.find((app) => app.id === appId)));
  
      const newResource = {
        id: `temp-${crypto.randomUUID()}`,
        link: '',
        file_name: '',
        appId: appId,
      };
  
      return {
        ...prev,
        [appId]: {
          ...currentApp,
          resources: [...(currentApp.resources || []), newResource],
        },
      };
    });
  };
  
  const handleDeleteResource = (releaseId: number, resourceId: number | string) => {
    setEditedData((prev) => {
      const currentRelease =
        prev[releaseId] ||
        JSON.parse(JSON.stringify(release.find((r) => r.id === releaseId)));
      const updatedResources = currentRelease.releaseResources.filter(
        (resource) => resource.id !== resourceId
      );
  
      return {
        ...prev,
        [releaseId]: {
          ...currentRelease,
          releaseResources: updatedResources,
        },
      };
    });
  };
  
  const handleDeleteAppResource = (appId: number, resourceId: number) => {
    setEditedData((prev) => {
      const currentApp =
        prev[appId] ||
        JSON.parse(JSON.stringify(apps.find((app) => app.id === appId)));
  
      // Filter out the resource with the specified resourceId from current resources.
      const updatedResources = (currentApp.resources || []).filter(
        (resource) => resource.id !== resourceId
      );
  
      return {
        ...prev,
        [appId]: {
          ...currentApp,
          resources: updatedResources,
        },
      };
    });
  };
  
  const toggleAccordion = (id: string) => {
    setOpenAccordions(prev => ({
      [id]: !prev[id]
    }));
    setSelectRelease(id);
  };
  
  return (
    <>
      <SectionContainer>
        <div className='containerContent'>
          <p className='mb-0'>
            The Premium Customer Portal is a 360° view of your Premium Services
            entitlements, built to maximize your Premium Services benefits and
            enhance your overall experience.
          </p>
        </div>

        {isEditing && (    
          <div className='d-flex justify-content-end edit-buttons'> 
            <Button 
              type='secondary' 
              onClick={() => setShowModalRelease(!showModalRelease)}
              text='New Release'
              icon='PlusIcon'
            />
          </div>   
          )}
        <div>
          <Tabs
            type='border'
            tabs={releaseYears.map((year) => ({
              label: year.year,
              id: year.id,
            }))}
            position='center'
            activeTab={activeYear}
            onTabChange={(newYearId) => {
              setActiveYear(newYearId);
              setSelectRelease('');
              setOpenAccordions({});
            }}
          />
          <YearsContent>
            <ReleaseTitle>Previous Releases: What's new?</ReleaseTitle>
            <Tabs
              type='color'
              tabs={releaseMonth.map((month) => ({
                label: month.month,
                id: month.id,
              }))}
              position='left'
              activeTab={activeMonth}
              paddingBottom={false}
              onTabChange={(newMonthId) => {
                setActiveMonth(newMonthId);
                setSelectRelease('');
                setOpenAccordions({});
              }}
            />
            {isLoading && (
              <div className='spinner'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <ContainerEditor>
              {!isLoading &&
                release?.length > 0 &&
                release.map((release: any) => (
                  <ReleaseStyle key={release.id}>
                    {isEditing ? (
                      <>
                        <ContainerDate>
                          <TitleDate>Release Date:</TitleDate>
                          <Date>{release.date}</Date>
                        </ContainerDate>
                        <Tiptap
                          initialContent={release.description}
                          onChange={(content) => {
                            setEditedData((prev) => ({
                              ...prev,
                              [release.id]: {
                                ...(prev[release.id] || release),
                                description: content,
                              },
                            }));
                          }}
                        />
                        <ContainerLink>
                          <FlexContainer>
                            <ResourceTitle>Resources</ResourceTitle>
  
                            <div>
                              <GhostButton
                                onClick={() => handleAddResource(release.id)}
                                $variant='bordered'
                              >
                                Add Resource{' '}
                                <PlusIcon width={24} height={24} />
                              </GhostButton>
                            </div>
                          </FlexContainer>
  
                          {(editedData[release.id]?.releaseResources ||
                            release?.releaseResources
                          ).map((link, index) => (
                            <ResourceContainer key={link.id}>
                              <PDInput
                                value={link.file_name}
                                placeholder='File Name'
                                onChange={(newValue) => {
                                  setEditedData((prev) => {
                                    const currentData = prev[release.id] || {
                                      ...release,
                                    };
                                    const updatedResources =
                                      currentData.releaseResources.map((res, i) =>
                                        i === index
                                          ? { ...res, file_name: newValue }
                                          : res
                                      );
  
                                    return {
                                      ...prev,
                                      [release.id]: {
                                        ...currentData,
                                        releaseResources: updatedResources,
                                      },
                                    };
                                  });
                                }}
                              />
                              <PDInput
                                value={link.link}
                                placeholder='File URL'
                                onChange={(newValue) => {
                                  setEditedData((prev) => {
                                    const currentData = prev[release.id] || {
                                      ...release,
                                    };
                                    const updatedResources =
                                      currentData.releaseResources.map((res, i) =>
                                        i === index
                                          ? { ...res, link: newValue }
                                          : res
                                      );
  
                                    return {
                                      ...prev,
                                      [release.id]: {
                                        ...currentData,
                                        releaseResources: updatedResources,
                                      },
                                    };
                                  });
                                }}
                              />
                              <GhostButton
                                onClick={() =>
                                  handleDeleteResource(release.id, link.id)
                                }
                              >
                                <TrashIcon width={24} height={24} />
                              </GhostButton>
                            </ResourceContainer>
                          ))}
                        </ContainerLink>
                      
                      </>
                    ) : (
                      <AccordionContainer>
                        <AccordionHeader onClick={() => toggleAccordion(release.id)}>
                          <ContainerDate>
                            <TitleDate>Release Date:</TitleDate>
                            <Date>{release.date}</Date>
                          </ContainerDate>
                          <Arrow isOpen={openAccordions[release.id]}>
                            <ChevronDownIcon />
                          </Arrow>
                        </AccordionHeader>
  
                        {selectRelease === release.id &&  (
                          <AccordionContent isOpen={openAccordions[release.id]}>
                            <HTMLContent
                              dangerouslySetInnerHTML={{
                                __html: release.description,
                              }}
                            />
  
                            <ContainerLink>
                              {release?.releaseResources.map(
                                (link: ReleaseResource) => (
                                  <ResourceContainer key={link.id}>
                                    <ResourcePropsContainer>
                                      <span>{link.file_name || "Undefined file"}</span>
                                      <span className='link'>{link.link}</span>
                                    </ResourcePropsContainer>
                                    <GhostButton $variant='bordered' $color='#048A24' $borderColor='#048A24' onClick={() => download(link.id)} disabled={loadingButton === link.id}>
                                    {loadingButton === link.id ? 'Downloading...' : 'Download'}
                                    {loadingButton !== link.id && <ArrowDownTrayIcon />}
                                    </GhostButton>
                                  </ResourceContainer>
                                )
                              )}
                            </ContainerLink>
                          </AccordionContent>
                        )}
                      </AccordionContainer>
                    )}
                  </ReleaseStyle>
                ))}
            </ContainerEditor>
   
            {showModalRelease && (
              <ModalNewRelease 
                showModalRelease={showModalRelease} 
                releaseMonth={releaseMonth}
                setShowModalRelease={(e: boolean) => setShowModalRelease(e)}
                getRelease={() => getRelease()}
              />
            )}
   
            {showModalTabApp && (
              <ModalNewTabApp
                showModalTabApp={showModalTabApp} 
                setShowModalTabApp={(e: boolean) => setShowModalTabApp(e)}
                getApplications={() => getApplications()}
              />
            )}
  
          </YearsContent>
          {isEditing && (
            <>
            <div className='d-flex justify-content-end'>
              <Button
                onClick={handleSave}
                type='primary'
                text='Save'
                icon='CheckIcon'
              />

            </div>
            </>
          )}
        </div>
        {isEditing && (
          <ButtonsContainer $position='end'>
       
              <Button
                onClick={() => setShowModalTabApp(!showModalTabApp)}
                type='secondary'
                text='Add New Application'
                icon='PlusIcon'
              />
          
          </ButtonsContainer>
        )}
        <div>
          <TabContainer>
            <Tabs
              type='border'
              tabs={apps.map((app: any) => ({ label: app.name, id: app.id }))}
              position='center'
              activeTab={activeTab}
              onTabChange={(e) => setActiveTab(e)}
            />
          </TabContainer>
          <TabsContent>
            {apps.map((app) => (
              app.id === activeTab && (
                <div key={app.id}>
                  <ReleaseTitle>{app.title}</ReleaseTitle>
                  
                  {/* Application Content */}
                  <ContainerEditor>
                    {isEditing ? (
                      <>
                        <Tiptap
                          initialContent={app.description}
                          onChange={(content) => {
                            setEditedData((prev) => ({
                              ...prev,
                              [app.id]: {
                                ...app,
                                description: content
                              }
                            }));
                          }}
                        />
         
                      </>
                    ) : (
                      <HTMLContent
                        dangerouslySetInnerHTML={{
                          __html: app.description,
                        }}
                      />
                    )}
                  </ContainerEditor>
  
                  {/* Resources Section */}
                  <FlexContainer>
                    <ResourceTitle>Resources</ResourceTitle>
                    {isEditing && (
                      <div>
                        <GhostButton
                          onClick={() => handleAddAppResource(app.id)}
                          $variant='bordered'
                        >
                          Add Resource{' '}
                          <PlusIcon width={24} height={24} />
                        </GhostButton>
                      </div>
                    )}
                  </FlexContainer>
  
                  {/* Resources List */}
                  {(editedData[app.id]?.resources || app.resources).map((resource, index) => (
                    <div key={resource.id}>
                      {isEditing ? (
                        <ResourceContainer>
                          <PDInput
                            value={
                              editedData[app.id]?.resources?.[index]?.file_name ?? 
                              resource.file_name
                            }
                            placeholder='File Name'
                            onChange={(newValue) => {
                              setEditedData((prev) => {
                                // Retrieve the current edited data, or initialize from the original app data.
                                const currentData = prev[app.id] || { ...app };
                                // Use currentData.resources (which may have been previously edited) if available; 
                                // otherwise fallback to app.resources.
                                const currentResources = currentData.resources || app.resources;
                                const updatedResources = currentResources.map((res, i) =>
                                  i === index ? { ...res, file_name: newValue } : res
                                );
                                return {
                                  ...prev,
                                  [app.id]: {
                                    ...currentData,
                                    resources: updatedResources,
                                  },
                                };
                              });
                            }}
                          />
                          <PDInput
                            value={
                              editedData[app.id]?.resources?.[index]?.link ?? 
                              resource.link
                            }
                            placeholder='File URL'
                            onChange={(newValue) => {
                              setEditedData((prev) => {
                                // Retrieve the current edited data, or initialize from the original app data.
                                const currentData = prev[app.id] || { ...app };
                                // Use currentData.resources (which may have been previously edited) if available; 
                                // otherwise fallback to app.resources.
                                const currentResources = currentData.resources || app.resources;
                                const updatedResources = currentResources.map((res, i) =>
                                  i === index ? { ...res, link: newValue } : res
                                );
                                return {
                                  ...prev,
                                  [app.id]: {
                                    ...currentData,
                                    resources: updatedResources,
                                  },
                                };
                              });
                            }}
                          />
                          <GhostButton
                            onClick={() => handleDeleteAppResource(app.id, resource.id)}
                          >
                            <TrashIcon width={24} height={24} />
                          </GhostButton>
                        </ResourceContainer>
                      ) : (
                        <ResourceContainer>
                            <ResourcePropsContainer>
                              <span>{resource.file_name || "Undefined file"}</span>
                              <span className='link'>{resource.link}</span>
                            </ResourcePropsContainer>
                            <GhostButton $variant='bordered' $color='#048A24' $borderColor='#048A24' onClick={() => downloadAppResource(resource)} disabled={loadingButton === resource.id}>
                              {loadingButton === resource.id ? 'Downloading...' : 'Download'}
                              {loadingButton !== resource.id && <ArrowDownTrayIcon />}
                            </GhostButton>
                        </ResourceContainer>
                      )}
                    </div>
                  ))}
                  {isEditing && (
                    <div className='d-flex justify-content-end'>
                      <Button
                        onClick={() => handleSaveApplications(app.id)}
                        type='primary'
                        text={isSaving ? 'Saving...' : 'Save'}
                        icon='CheckIcon'
                        disabled={isSaving}
                      />
                    </div>
                  )}
                </div>
              )
            ))}
          </TabsContent>
        </div>
      </SectionContainer>
      <ButtonsContainer $position='end'>
        {isEditing ? (
          <>
            <Button
              onClick={handleCancel}
              type='secondary'
              text='Cancel'
            />
          </>
        ) : (
          showEditInfo && (
            <Button
              onClick={() => setIsEditing(true)}
              type='primary'
              text='Edit'
            />
          )
        )}
      </ButtonsContainer>
    </>
  );
};
  
export default LearningHub;