import { TabsType } from "./Tabs.model";
import { TabsContainer, TabsStyled } from "./Tabs.styled";

export const Tabs = ({
  type = "border",
  activeTab,
  tabs,
  onTabChange,
  paddingBottom = true,
  position = "center",
}: TabsType) => {

  return (
    <TabsContainer $type={type} $position={position}>
      {tabs.map((tab) => {
        return (
          <TabsStyled
            key={tab.label}
            $paddingBottom={paddingBottom}
            $activeProp={activeTab === tab.id}
            $type={type}
            onClick={() => onTabChange(tab.id)}
          >
            {tab.label}
          </TabsStyled>
        );
      })}
    </TabsContainer>
  );
};

Tabs.displayName = "Tabs";

export default Tabs;
