import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import './Banner.css';
import { bannerSubject } from './../../services/subjects';

const Banner = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const subscription = bannerSubject.subscribe((data) => {
      setBannerData(data);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!bannerData) return null;

  const handleDismiss = () => {
    setBannerData(null);
  };

  if (bannerData.message == null || bannerData.message == "") {
    return <></>;
  }

  return (
    <div className={`banner banner-${bannerData.type}`}>
      <div className="banner-content">
        {bannerData.icon && (
          <FontAwesomeIcon
            icon={bannerData.icon}
            className="maintenance-banner-icon"
          />
        )}
        <span className="banner-message">{bannerData.message}</span>
        {bannerData.type.endsWith("-modern") && (
          <div
            className="maintenance-banner-dismiss-icon"
            ariaButton="button"
            onClick={handleDismiss}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
