import { useState, createContext, useMemo, useContext } from "react";

const UserContext = createContext({
  menu: null,
  profile: null,
  profileSet() {},
  menuSet() {},
});

export const UserProvider = ({ children }) => {
  const [menu, setMenu] = useState(null);
  const [profile, setProfile] = useState(null);
  const [login, setLogin] = useState(false);
  const [cartAlert, setCartAlert] = useState([]);
  const [queryParams, setQueryParams] = useState({});

  const sessionContext = useMemo(() => ({
    profileSet: (data) => {
      setProfile(data);
    },
    menuSet: (data) => {
      setMenu(data);
    },
    addAlertToCart: (item) => {
      setCartAlert((prevState) => {
        const newState = [...prevState];
        const itemIndex = newState.indexOf(item);
        if (itemIndex === -1) {
          newState.push(item);
        }

        return newState.flat();
      });
    },
    deleteAlertsInCart: (alert) => {
      const alerts = cartAlert?.filter((item) => item?.id !== alert);
      setCartAlert(alerts);
    },
    deleteAllAlertsTeamInCart: (alert) => {
      const alerts = cartAlert?.filter(
        (cartItem) => !alert.some((alertItem) => cartItem.id === alertItem.id),
      );
      setCartAlert(alerts);
    },
    clearAllAlerts: () => {
      setCartAlert([]);
    },
    addQueryParams: (
      key,
      value,
      asArray = true,
      onButton = false,
      object = {},
    ) => {
      if (onButton) {
        setQueryParams(object);
      } else {
        setQueryParams((prevParams) => {
          const newParams = { ...prevParams };
          const isEmptyValue =
            value === undefined || value === null || value === "";

          if (!isEmptyValue) {
            if (asArray) {
              if (newParams.hasOwnProperty(key)) {
                const index = newParams[key].indexOf(value);
                if (index !== -1) {
                  newParams[key] = newParams[key].filter((v) => v !== value);
                  if (newParams[key].length === 0) {
                    delete newParams[key];
                  }
                } else {
                  newParams[key] = [...newParams[key], value];
                }
              } else {
                newParams[key] = [value];
              }
            } else {
              newParams[key] = value;
            }
          } else {
            delete newParams[key];
          }

          return newParams;
        });
      }
    },
    clearQueryParams: () => {
      setQueryParams({});
    },
    menu,
    profile,
    cartAlert,
    queryParams,
  }));

  return (
    <UserContext.Provider value={sessionContext}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);

