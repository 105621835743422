import styled from 'styled-components';

export const AccordionContainer = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const AccordionHeader = styled.div`
  padding: 16px;
  /* background-color: #f4f4f4; */
  border: 1px solid #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & div {
    margin-bottom: 0px;
  }
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  padding: 16px;
  padding-top: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  padding-bottom: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  max-height: ${({ isOpen }) => (isOpen ? '490px' : '0')};
  overflow: auto;
  transition: all 0.3s ease-in-out;
`;

export const Arrow = styled.span<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
  display: inline-block;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const TabContainer = styled.div`
  & p {
    font-size: 16px;
    font-weight: 100;
  }
`;
export const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
      position: absolute;
      left: 0;
    }
  }

  & > li {
    &::before {
      content: '•';
      color: rgb(19 113 112);
      font-size: 1.2em;
    }
  }
`;
export const SubStyledList = styled.ul`
  margin-left: 16px;
  & > li {
    &::before {
      content: '◦';
      color: rgb(19 113 112);
      font-size: 1em;
    }
  }
  li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  &::before {
    position: absolute;
    left: 0;
  }
`;

interface GhostButtonProps {
  $variant?: 'bordered' | 'default';
  $borderColor?: string;
  $borderWidth?: string;
  $color?: string;
}

export const GhostButton = styled.button<GhostButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  border: ${(props) =>
    props.$variant === 'bordered'
      ? `${props.$borderWidth || '1px'} solid ${
          props.$borderColor || '#E9E9E9'
        }`
      : 'none'};
  color: ${(props) => (props.$color ? props.$color : '#117170')};
  &:hover {
    background-color: #e9e9e9;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const FadeContent = styled.div<{ visible: boolean }>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
`;

export const ReleaseTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
`;

export const ContentTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
`;

export const TerminologyTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
`;

export const TabTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
`;

export const RealeasDate = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

export const YearsContent = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
`;

export const TabsContent = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
`;

export const ResourceTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 16px;
  margin-bottom: 0px;
`;
export const ContainerEditor = styled.div`
& svg {
      height: 1.2em;
      display: flex;
    }
`;

export const HTMLContent = styled.div`
  padding: 16px;
  /* border: 1px solid #e9e9e9; */
  border-radius: 8px;
  overflow: auto;
  & h1 {
      font-size: 1.6em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.3em;
      font-weight: bold;
    }
    h3 {
      font-size: 1.1em;
      font-weight: bold;
    }
    strong {
      font-weight: bolder;
    }
    & ul, ol {
      margin-left: 24px;
    }
    ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    }
    li{
        margin-bottom: 0px;
    }

    li p {
        margin-bottom: 8px;
    }
  
`;
export const ResourceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 16px;
  padding: 16px;
  border-left: 3px solid #117170;
  border-radius: 16px;
  background-color: #fafafa;
  justify-content: space-between;

  // Aplicar flex: 1 a los PDInput
  & > PDInput {
    flex: 1;
  }
  // O si PDInput es un styled component
  & > input {
    flex: 1;
  }

  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const ResourcePropsContainer = styled.div`
  display: flex;
  gap: 24px;
  & .link {
    color: gray;
    display: inline-block;    /* or block/flex as needed */
    max-width: 600px;         /* Adjust the max-width as appropriate */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;


export const ContainerRelease = styled.div`
  padding: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: scroll;
`;

export const ReleaseStyle = styled.div`
  margin-bottom: 0px;
`;

export const Date = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: white;
  background-color: #117170;
  padding: 8px;
  border-radius: 8px;
`;

export const TitleDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #343434;
`;

export const ContainerDate = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Description = styled.div`
  font-family: Plain;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0px;
`;

export const ContainerLink = styled.div`
  margin-bottom: 16px;
  display: grid;
  gap: 16px;
`;

export const Link = styled.div`
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  border-left: 3px solid #117170;
  display: flex;
  background: #fafafa;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const ButtonNewRelease = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  color: #646464;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: end;
  cursor: pointer;
`;

export const ButtonNewReleaseSve = styled.div`
  display: flex;
  justify-content: end;
`;
export const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  & h2 {
    margin-bottom: 0px;
  }
`;
export const FlexInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  & h2 {
    margin-bottom: 0px;
  }
`;
export const ModalContainerFlex = styled.div`
  display: flex;
  flex-flow: column;
  gap: 24px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
`;

export const Select = styled.select`
  outline: none;
    padding: 16px;
    display: block;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(108, 117, 125);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    appearance: none;
    border-radius: 10px;
    border: 1px solid rgb(224, 224, 224);
`;