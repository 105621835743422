import { useEffect, useState } from 'react';
import { Alert, AlertItem } from './HeatmapAlert.model';
import { MonthName, SeverityID, EntityName } from '@/pages/MyAlertsApp/models';
import { getSeverity } from '@/pages/MyAlertsApp/utils';
import {
  AlertSeverityLegend,
  CenteredMessage,
  HeatmapContainer,
  HeatmapEntityAlertGrid,
  HeatmapGrid,
  HeatmapCard,
} from '@/pages/MyAlertsApp/components';
import SubTitleApp from '@/components/SubTitleApp';

interface HeatmapAlertProps {
  alertsHeatmap: AlertItem[];
  onClickCheckAlertTrends: (alert: string, entity: string) => void;
  selectedEntities?: EntityName[];
  selectedSeverities?: SeverityID[];
  selectedMonth?: MonthName;
}

const filterAlert = (
  alert: Alert,
  selectedSeverities?: SeverityID[],
  selectedEntities?: EntityName[],
  selectedMonth?: MonthName
) => {
  const severityMatch =
    !selectedSeverities?.length ||
    selectedSeverities.includes(alert.severity as SeverityID);
  const entityMatch =
    !selectedEntities?.length ||
    selectedEntities.includes(alert.entity as EntityName);
  const monthMatch = !selectedMonth || selectedMonth === alert.month;
  return severityMatch && entityMatch && monthMatch;
};

export const HeatmapAlert = ({
  alertsHeatmap,
  onClickCheckAlertTrends,
  selectedSeverities,
  selectedEntities,
  selectedMonth,
}: HeatmapAlertProps) => {
  const [alertItems, setAlertItems] = useState<AlertItem[] | []>(alertsHeatmap);

  useEffect(() => {
    applyAlertFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeverities?.length, selectedEntities?.length, selectedMonth]);

  const applyAlertFilters = () => {
    setAlertItems(
      alertsHeatmap.map(({ entityName, alerts }) => {
        return {
          entityName,
          alerts: alerts.filter((alert) =>
            filterAlert(
              alert,
              selectedSeverities,
              selectedEntities,
              selectedMonth
            )
          ),
        };
      })
    );
  };

  const alertsQuantityIsZero = alertItems?.every(
    ({ alerts }) => alerts.length === 0
  );

  const onClick = (alert: Alert) => {
    if (onClickCheckAlertTrends) {
      onClickCheckAlertTrends(
        alert?.field_commercial_name,
        alert?.entity_label || alert?.entity
      );
    }
  };

  return (
    <HeatmapContainer>
      <AlertSeverityLegend />
      {alertsQuantityIsZero && (
        <CenteredMessage>
          Oops! No alerts were found for the selected filters. Please try
          adjusting your filters and try again.
        </CenteredMessage>
      )}

      {!alertsQuantityIsZero &&
        alertItems?.map(({ entityName, alerts }, index) => {
          if (alerts.length === 0) return null;

          return (
            <div key={`${entityName}-${index}`}>
              <HeatmapEntityAlertGrid>
                <SubTitleApp>{entityName}</SubTitleApp>
                <HeatmapGrid>
                  {alerts?.map((alert, index) => {
                    const { bgColor, textColor } = getSeverity(alert?.severity);

                    return (
                      <HeatmapCard
                        key={index}
                        onClick={() => onClick(alert)}
                        bgColor={bgColor}
                        textColor={textColor}
                      >
                        <p>{alert?.field_commercial_name}</p>
                        <span>Alerts {alert?.count}</span>
                      </HeatmapCard>
                    );
                  })}
                </HeatmapGrid>
              </HeatmapEntityAlertGrid>
            </div>
          );
        })}
    </HeatmapContainer>
  );
};

export default HeatmapAlert;
