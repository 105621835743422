import styled from "styled-components";

export const LoginPanel = styled.div`
  background-image: url("https://images.innovation.csg.pagerduty.com/loginBackgound1.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  width: 70%;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100%;
    display: none;
  }
`;

export const TestimonialText = styled.p`
  font-style: italic;
`;

export const LoginContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const LoginForm = styled.div`
  padding: 4em;
  background-color: var(--background-color);
  width: 30%;
  max-width: 34.37rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 3px -2px 5px black;
  z-index: 2;
  min-width: 500px;
  @media (max-width: 768px) {
    box-shadow: none;
    min-width: 300px;
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 60px;
`;

export const LoginDescription = styled.span`
  align-self: baseline;
  font-size: 12px;
  margin-bottom: 15px;
  color: grey;
  display: block;
  text-align: center;
`;

export const LoginGreetings = styled.h1`
  font-size: 24px;
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
`;

export const Divider = styled.span`
  width: 100%;
  text-align: center;
  &:before {
    content: "";
    display: inline-block;
    width: 140px;
    height: 1px;
    background-color: grey;
    margin-bottom: 3px;
    margin-right: 8px;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 140px;
    height: 1px;
    background-color: grey;
    margin-bottom: 3px;
    margin-left: 8px;
  }
`;

export const LoginButton = styled.button`
  background: var(--primary-color);
  color: white;
  padding: 10px 20px;
  font-weight: 600;
  border: none;
  border-radius: 0.375rem;
  width: 100%;
  margin: 20px 0px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, #094354, #21a136);
    transition: background-color 0.3s ease-in-out;
  }
`;

export const LoginInfoFooter = styled.span`
  text-align: center;
`;

export const LoginInfoFooterLink = styled.a`
  color: #06ac38;
  text-decoration-line: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const CarouselDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewsSection = styled.div`
  width: 100%;
  height: fit-content;
  padding: 32px;
  border-radius: 16px;
  opacity: 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  `;

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

export const NewsTitle = styled.h3`
font-weight: bold;
font-size: 24px;
line-height: 36px;
letter-spacing: 0.25px;
text-align: left;
color: #000000;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InnerContainerTitle = styled.h4`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.25px;
  color: #000000;
`;

export const InnerContainerList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const NewsDescription = styled.p`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #535353;
  margin: 0;
`;