import { useMemo, useState, Fragment } from "react";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RESOURCES_TITLE_TEXT } from "./constants";

/** @param {Object} props
 * @param {import("./index").TFerReducerState} props.tferState
 * @param {import("./index").RequestTerraformation} props.requestTerraformation
 * @param {() => void} props.requestTerraformation
 */
export const TerraformationPlan = ({ tferState, requestTerraformation }) => {
  const terraformationMode = tferState.terraformationMode;
  const [requestTerraformationPending, setRequestTerraformationPending] =
    useState(false);

  const sizeOfPlan = (plan) => {
    return Object.values(plan).reduce(
      (size, resources) => size + resources.length,
      0,
    );
  };

  const isPlanEmpty = useMemo(() => {
    return sizeOfPlan(tferState.terraformationPlan) === 0;
  }, [tferState.terraformationPlan]);

  const renderTerraformEverything = () => {
    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code className="tfer-keyword">{tferState.customerSubdomain}</code>{" "}
          extracting all PagerDuty resources of the following types:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul>
            {Object.keys(RESOURCES_TITLE_TEXT).map((resourceType) => (
              <li
                key={resourceType}
                className={tferState.plan?.[resourceType]?.length ? "open" : ""}
              >
                {RESOURCES_TITLE_TEXT[resourceType]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  /**
   * @param {Object} props
   * @param {import("./index").PagerDutyResourceData[]} resources
   */
  const ResourceItems = ({ resources }) =>
    resources?.map((item) => (
      <li key={item.id} className="tf-app__show-plan-sub-item">
        {item == null || item.html_url == null || item.html_url === "" ? (
          item.summary
        ) : (
          <a target="_blank" href={item.html_url}>
            {" "}
            {item.summary}{" "}
          </a>
        )}
      </li>
    ));

  const renderTerraformPlan = () => {
    /**
     * @param {import("./index").TFerTerraformationPlan} resources
     * @param {import("./index").TFerStateResourceType} resourceType
     */
    const renderResourceItems = (resources, resourceType) => {
      if (resources[resourceType].length > 0) {
        return (
          <Fragment key={resourceType}>
            <li>{RESOURCES_TITLE_TEXT[resourceType]}</li>
            <ResourceItems resources={resources[resourceType]} />
          </Fragment>
        );
      }
    };

    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code className="tfer-keyword">{tferState.customerSubdomain}</code>{" "}
          extracting the following PagerDuty resources:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul className="tfer-app__show-plan-list">
            {Object.keys(tferState.terraformationPlan).map((resourceType) => {
              return renderResourceItems(
                tferState.terraformationPlan,
                resourceType,
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  const startTerraformation = async () => {
    const terraformEverything = terraformationMode === "everything";
    const terraformationPlan = Object.values(
      tferState.terraformationPlan,
    ).reduce((plan, resource) => plan.concat(resource), []);
    setRequestTerraformationPending(true);
    await requestTerraformation(terraformEverything, terraformationPlan);
    setRequestTerraformationPending(false);
  };

  return (
    <div className="tfer-app__show-plan-container">
      <div className="tfer-app__plan-btn-container">
        <button
          className="tfer-app__btn tfer-app__btn--generate-tf"
          onClick={() => {
            startTerraformation();
          }}
          disabled={
            (isPlanEmpty && terraformationMode !== "everything") ||
            requestTerraformationPending
          }
        >
          <FontAwesomeIcon icon={faGears} style={{ marginRight: 10 }} />
          Generate Terraform Code
        </button>
      </div>
      {terraformationMode === "plan" &&
        (isPlanEmpty ? (
          <p className="tfer-app--warning-text">
            A terraformation plan must be chosen, either by selecting the
            PagerDuty resources to be managed by Terraform, or by selecting
            terraformate everything.
          </p>
        ) : (
          renderTerraformPlan()
        ))}
      {terraformationMode === "everything" && renderTerraformEverything()}
    </div>
  );
};
