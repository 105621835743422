import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import './TipTapComponent.css';
import { useEffect, useState } from 'react';
import { NumberedListIcon, H1Icon, H2Icon, Bars3BottomLeftIcon, Bars3BottomRightIcon, BoldIcon, ItalicIcon, ListBulletIcon, LinkIcon, H3Icon, ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/react/24/outline';

interface TiptapEditorProps {
  initialContent?: string;
  onChange?: (content: string) => void;
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({ 
  initialContent = '', 
  onChange 
}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Color,
      TextStyle,
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && initialContent !== editor.getHTML()) {
      editor.commands.setContent(initialContent);
    }
  }, [initialContent, editor]);

  if (!editor) {
    return null;
  }

  const addLink = () => {
    if (!linkUrl) return;
    
    editor
      .chain()
      .focus()
      .setLink({ href: linkUrl })
      .run();

    setLinkUrl('');
    setIsLinkModalOpen(false);
  };

  return (
    <div className="overflow-hidden">
      {/* Toolbar */}
      <div className="border-b p-2 flex flex-wrap gap-2 tiptapIconBar bg-gray-50">
        {/* Text Formatting */}
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().undo()}
          className={`p-2 rounded tiptapButton hover:bg-gray-100 tiptapButton ${
            !editor.can().undo() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <ArrowUturnLeftIcon/>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().redo()}
          className={`p-2 rounded tiptapButton hover:bg-gray-100 tiptapButton ${
            !editor.can().redo() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <ArrowUturnRightIcon/>
        </button>
        {/* Color Picker */}
        <div className="relative flex align-items-center">
          <input
            type="color"
            onChange={(e) => {
              editor?.chain().focus().setColor(e.target.value).run();
            }}
            className="w-8 h-8 p-1 rounded cursor-pointer inputColor"
            title="Text color"
          />
        </div>

        {/* Remove color */}
        <button
          onClick={() => editor?.chain().focus().unsetColor().run()}
          className="p-2 rounded tiptapButton hover:bg-gray-100 tiptapButton"
          title="Remove color"
        >
          Clear color
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('bold') ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
         <BoldIcon/>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('italic') ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <ItalicIcon/>
        </button>

        <div className="w-px h-6 bg-gray-300 mx-2" /> {/* Divider */}

        {/* Headings */}
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('heading', { level: 1 }) ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <H1Icon/>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('heading', { level: 2 }) ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <H2Icon/>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('heading', { level: 3 }) ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <H3Icon/>
        </button>

        <div className="w-px h-6 bg-gray-300 mx-2" />

        {/* Alignment */}
        <button
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={`p-2 rounded tiptapButton ${editor.isActive({ textAlign: 'left' }) ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <Bars3BottomLeftIcon/>
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={`p-2 rounded tiptapButton ${editor.isActive({ textAlign: 'right' }) ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <Bars3BottomRightIcon/>
        </button>

        <div className="w-px h-6 bg-gray-300 mx-2" />

        {/* Lists */}
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('bulletList') ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <ListBulletIcon/>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`p-2 rounded tiptapButton ${editor.isActive('orderedList') ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <NumberedListIcon/>
        </button>

        <div className="w-px h-6 bg-gray-300 mx-2" />

        {/* Media */}
        <button
          onClick={() => setIsLinkModalOpen(true)}
          className={`p-2 rounded tiptapButton ${editor.isActive('link') ? 'activeButton' : 'hover:bg-gray-100'}`}
        >
          <LinkIcon/>
        </button>
      </div>

      {/* Editor Content */}
      <div className="editorContentTipTap p-4 pt-2 pb-2 border rounded-lg">
        <EditorContent editor={editor} />
      </div>

      {/* Link Modal */}
      {isLinkModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg">
            <input
              type="url"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="Enter URL"
              className="border p-2 rounded tiptapButton"
            />
            <div className="mt-4 flex gap-2">
              <button
                onClick={addLink}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add Link
              </button>
              <button
                onClick={() => setIsLinkModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TiptapEditor;