const ENTITY_USER = "user";
const ENTITY_SERVICE = "service";
const ENTITY_ESCALATION_POLICY = "escalation_policy";
const ENTITY_SCHEDULE = "schedule";
const ENTITY_TEAMS = "team";

const SEVERITY_CRITICAL = "Critical";
const SEVERITY_MAJOR = "Major";
const SEVERITY_HIGH = "High";
const SEVERITY_MEDIUM = "Medium";
const SEVERITY_LOW = "Low";
const SEVERITY_MINOR = "Minor";

const SEVERITY_CRITICAL_ID = 1;
const SEVERITY_MAJOR_ID = 13;
const SEVERITY_HIGH_ID = 11;
const SEVERITY_MEDIUM_ID = 4;
const SEVERITY_LOW_ID = 2;
const SEVERITY_MINOR_ID = 12;

const MONTH_JANUARY = "January";
const MONTH_FEBRUARY = "February";
const MONTH_MARCH = "March";
const MONTH_APRIL = "April";
const MONTH_MAY = "May";
const MONTH_JUNE = "June";
const MONTH_JULY = "July";
const MONTH_AUGUST = "August";
const MONTH_SEPTEMBER = "September";
const MONTH_OCTOBER = "October";
const MONTH_NOVEMBER = "November";
const MONTH_DECEMBER = "December";

const MONTH_ORDER = {
  [MONTH_JANUARY]: 0,
  [MONTH_FEBRUARY]: 1,
  [MONTH_MARCH]: 2,
  [MONTH_APRIL]: 3,
  [MONTH_MAY]: 4,
  [MONTH_JUNE]: 5,
  [MONTH_JULY]: 6,
  [MONTH_AUGUST]: 7,
  [MONTH_SEPTEMBER]: 8,
  [MONTH_OCTOBER]: 9,
  [MONTH_NOVEMBER]: 10,
  [MONTH_DECEMBER]: 11,
};

const BUTTON_TABS = [
  {
    name: `Team's Dashboard`,
    id: "team",
    route: "/my-alerts-app/team",
  },
  {
    name: `Health Check Alerts Overview`,
    id: "alerts",
    route: "/my-alerts-app",
  },
  {
    name: `Health Check Alerts Dashboard`,
    id: "dashboard",
    route: "/my-alerts-app/dashboard",
  },
];

const OPEN_CASE = {
  "status-select-multiple[]": [
    { label: "Pending", value: "Pending" },
    { label: "Working", value: "Working" },
    { label: "Open", value: "Open" },
    { label: "On Hold", value: "On Hold" },
    { label: "New", value: "New" },
    { label: "In Progress", value: "In Progress" },
    { label: "Pending Approval", value: "Pending Approval" },
    { label: "In Review", value: "In Review" },
  ],
};

const OPEN_CASE_REQUEST = {
  "status-select-multiple[]": [
    { label: "Pending", value: "Pending" },
    { label: "Working", value: "Working" },
    { label: "Open", value: "Open" },
    { label: "On Hold", value: "On Hold" },
    { label: "New", value: "New" },
    { label: "In Progress", value: "In Progress" },
    { label: "Pending Approval", value: "Pending Approval" },
    { label: "In Review", value: "In Review" },
  ],
};

const CLOSE_CASE_REQUEST = {
  "status-select-multiple[]": [
    { label: "Solved", value: "Solved" },
    { label: "Closed", value: "Closed" },
    { label: "Completed", value: "Completed" },
    { label: "Rejected", value: "Rejected" },
    { label: "Not Doing", value: "Not Doing" },
  ],
};

const CLOSED_CASE = {
  "status-select-multiple[]": [
    { label: "Solved", value: "Solved" },
    { label: "Closed", value: "Closed" },
    { label: "Completed", value: "Completed" },
    { label: "Rejected", value: "Rejected" },
    { label: "Not Doing", value: "Not Doing" },
  ],
};

const ROUTE_MAP = {
  "/my-alerts-app/team": "team",
  "/my-alerts-app": "alerts",
  "/my-alerts-app/dashboard": "dashboard",
};

const EXTERNAL_URL = {
  explore_cloud: "https://www.pagerduty.com/platform/operations-cloud/",
};

export {
  ENTITY_USER,
  ENTITY_SERVICE,
  ENTITY_ESCALATION_POLICY,
  ENTITY_SCHEDULE,
  ENTITY_TEAMS,
  SEVERITY_CRITICAL,
  SEVERITY_MAJOR,
  SEVERITY_HIGH,
  SEVERITY_MEDIUM,
  SEVERITY_LOW,
  SEVERITY_MINOR,
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER,
  SEVERITY_CRITICAL_ID,
  SEVERITY_MAJOR_ID,
  SEVERITY_HIGH_ID,
  SEVERITY_MEDIUM_ID,
  SEVERITY_LOW_ID,
  SEVERITY_MINOR_ID,
  MONTH_ORDER,
  BUTTON_TABS,
  ROUTE_MAP,
  EXTERNAL_URL,
  OPEN_CASE,
  CLOSED_CASE,
  OPEN_CASE_REQUEST,
  CLOSE_CASE_REQUEST,
};

