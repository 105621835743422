import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';
import {
  DeliverySectionWrapper,
  SectionHeader,
  Title,
  ToggleButton,
  TableContainer,
  TableWrapper,
  Table,
  Th,
  Td,
  EditInput,
  ActionButton,
  IconButton
} from './ClientDeliveryDashboard.styled';

interface DeliverySectionProps {
  loading: boolean;
  delivery: boolean;
  setDelivery: (value: boolean) => void;
  deliveryData: any[];
  editIndex: number | null;
  handleEdit: (index: number) => void;
  handleChange: (field: string, value: string) => void;
  handleSave: (item: any, index: number) => void;
  setEditIndex: (value: number | null) => void;
}

export const DeliverySection: React.FC<DeliverySectionProps> = ({
  loading,
  delivery,
  setDelivery,
  deliveryData,
  editIndex,
  handleEdit,
  handleChange,
  handleSave,
  setEditIndex
}) => {
  

  const ChevronIcon = ({ isExpanded }: { isExpanded: boolean }) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 20 20" 
      fill="currentColor" 
      aria-hidden="true" 
      className="icon-style"
    >
      <path 
        fillRule="evenodd" 
        d={isExpanded 
          ? "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          : "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
        } 
        clipRule="evenodd" 
      />
    </svg>
  );

  return (
    <DeliverySectionWrapper>
      <SectionHeader>
        <Title>Client Delivery Overview</Title>
        <ToggleButton onClick={() => setDelivery(!delivery)}>
          <ChevronIcon isExpanded={delivery} />
        </ToggleButton>
      </SectionHeader>

      {delivery && (
        <TableContainer>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Domain</Th>
                  <Th>Customer Type</Th>
                  <Th>Premium Service Type</Th>
                  <Th>Contract Start</Th>
                  <Th>Contract End</Th>
                  <Th>Account Manager</Th>
                  <Th>Delivery Manager</Th>
                  <Th>Portafolio Success Manager</Th>
                  <Th>Program Manager</Th>
                  <Th>Strategy Lead</Th>
                  <Th>Delivery Model</Th>
                  <Th>Project Plan</Th>
                  <Th>12mo Roadmap</Th>
                  <Th>Current/Future State</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {deliveryData.map((item, i) => (
                  <tr key={item.account_id}>
                    <Td isName>{item.customer_name}</Td>
                    <Td isName>{item.subdomain}</Td>
                    <Td>{item.theater}</Td>
                    <Td>{item.customer_type}</Td>
                    <Td>{item.delivery_summary_data?.close_date}</Td>
                    <Td>{item.end_date}</Td>
                    <Td>{item.account_manager}</Td>
                    <Td isCenter>{item.delivery_manager}</Td>
                    <Td isCenter>{item.portfolio_success_manager}</Td>
                    <Td isCenter>{item.program_manager}</Td>
                    <Td isCenter>{item.strategy_lead}</Td>
                    <Td isCenter>{item.delivery_model?.description || ''}</Td>
                    <Td isCenter>
                      {editIndex === i ? (
                        <EditInput
                          type="text"
                          onChange={e => handleChange('delivery_summary_data.delivery_project_plan', e.target.value)}
                          defaultValue={item.delivery_summary_data?.delivery_project_plan}
                        />
                      ) : (
                        item.delivery_summary_data?.delivery_project_plan && 
                        <a href={item.delivery_summary_data?.delivery_project_plan} target="_blank">
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      )}
                    </Td>
                    <Td isCenter>
                      {editIndex === i ? (
                        <EditInput
                          type="text"
                          onChange={e => handleChange('delivery_summary_data.delivery_12m_roadmap', e.target.value)}
                          defaultValue={item.delivery_summary_data?.delivery_12m_roadmap}
                        />
                      ) : (
                        item.delivery_summary_data?.delivery_12m_roadmap && 
                        <a href={item.delivery_summary_data?.delivery_12m_roadmap} target="_blank">
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      )}
                    </Td>
                    <Td isCenter>
                      {editIndex === i ? (
                        <EditInput
                          type="text"
                          onChange={e => handleChange('delivery_summary_data.delivery_cs_fs', e.target.value)}
                          defaultValue={item.delivery_summary_data?.delivery_cs_fs}
                        />
                      ) : (
                        item.delivery_summary_data?.delivery_cs_fs && 
                        <a href={item.delivery_summary_data?.delivery_cs_fs} target="_blank">
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      )}
                    </Td>
                    <Td isCenter>
                      {editIndex === i ? (
                        <>
                          <IconButton 
                            isSuccess
                            onClick={() => handleSave(item, i)}
                          >
                            <FontAwesomeIcon icon={faSave} />
                          </IconButton>
                          <IconButton 
                            onClick={() => setEditIndex(null)}
                          >
                            <FontAwesomeIcon icon={faCancel} />
                          </IconButton>
                        </>
                      ) : (
                        <ActionButton 
                          isEdit 
                          onClick={() => handleEdit(i)}
                        >
                          Edit
                        </ActionButton>
                      )}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </TableContainer>
      )}
    </DeliverySectionWrapper>
  );
};