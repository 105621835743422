import React, { FC, ChangeEvent, useEffect, useState } from "react";
import {
  SearchContainer,
  SearchInput,
  IconContainer,
  Input,
} from "./Search.styled.tsx";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SearchProps = {
  placeholder?: string;
  onSearch: (value: string) => void;
  debounceTime?: number;
  id?: string;
  showDebounce?: boolean;
  label?: string;
  clearValue: boolean;
  onKeyDown: () => void;
};

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Search: FC<SearchProps> = ({
  placeholder = "Search by title",
  onSearch,
  debounceTime = 1000,
  id,
  showDebounce = false,
  clearValue,
  onKeyDown,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, debounceTime);

  useEffect(() => {
    if (debouncedSearchValue.trim() && showDebounce) {
      onSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue, onSearch, showDebounce]);

  useEffect(() => {
    if (!showDebounce) {
      onSearch(searchValue);
    }
  }, [showDebounce, searchValue]);

  useEffect(() => {
    if (clearValue) {
      setSearchValue(""); // Limpia el input
    }
  }, [clearValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <SearchContainer>
      <SearchInput>
        <IconContainer>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </IconContainer>
        <Input
          id={id}
          type="text"
          value={searchValue}
          onChange={handleChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </SearchInput>
    </SearchContainer>
  );
};

export default Search;
