import { forwardRef, useImperativeHandle } from 'react';
import { DatePickerType } from './DatePicker.type';
import { DatePickerContainer } from './DatePicker.styled';
import { DatePicker as RsuiteDatePicker } from 'rsuite';
import { Icon } from '..';

export const DatePicker = forwardRef(
  (
    {
      show = true,
      value,
      onChange,
      placement = 'bottomEnd',
      size = 'StandAlone',
      disabled = false,
    }: DatePickerType,
    ref
  ) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <DatePickerContainer
        $show={show}
        $haveData={!(!value || disabled)}
        $size={size}
      >
        {(!value || disabled) && (
          <Icon icon='CalendarDaysIcon' type='outline' />
        )}
        <RsuiteDatePicker
          onChange={(date) => onChange && onChange(date)}
          value={value}
          placement={placement}
          size='md'
          format='MM, dd, yyyy'
          disabled={disabled}
        />
      </DatePickerContainer>
    );
  }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
