import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #828282;
`;

export const Input = styled.input`
  outline: none;
  width: 100%;
  font-family:
    Plain-Regular,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(224, 224, 224);
  transition: border-color 0.2s ease-in-out;
  min-height: 55px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px 8px 40px;
  &::placeholder {
    color: #828282;
    font-size: 14px;
    font-weight: 375;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`;

export const Button = styled.button`
  padding: 8px;
  border-radius: 8px;
  background: #048a24;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: rgb(4 59 17);
  }
`;
