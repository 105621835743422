import styled from "styled-components";

export const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const SlidesContainer = styled.div<{ currentIndex: number }>`
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
`;

export const Slide = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
`;

export const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
`;

export const ArrowLeft = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
  & svg {
    height: 30px;
    width: 30px;
  }
`;

export const ArrowRight = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
  & svg {
    height: 30px;
    width: 30px;
  }
`;

export const Dots = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Dot = styled.button<{ active: boolean }>`
  background: ${({ active }) => (active ? "#117170" : "#8f8f8f")};
  border: none;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  cursor: pointer;
  padding: 0;
`;
