import { useState, forwardRef, useEffect, useImperativeHandle, } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GroupedBarChart = forwardRef(({ labels, data, datasets, title, subTitle, onClickElement }, Ref) => {
    
  const [forceUpdate, setForceUpdate] = useState(true);

  useEffect((
      () => {
          setForceUpdate(!forceUpdate);
      }
  ), [data]);

  const [chartData, setChartData] = useState({
      datasets: [],
  });

  const amountConveter = (amount) => {
    if(Math.abs(amount) > 999 && Math.abs(amount) < 999999) {
      return Math.sign(amount) * (Math.abs(amount) / 1000).toFixed(1) + "k"
    } else if(Math.abs(amount) > 999 && Math.abs(amount) > 999999){
      return Math.sign(amount) * (Math.abs(amount) / 1000000).toFixed(1) + "m"
    } else {
      return Math.sign(amount) * Math.abs(amount);
    }
  }

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
      setChartData({
          labels: labels,
          datasets: datasets,
      });
      setChartOptions({
          responsive: true,
          plugins: [{
            beforeInit(chart,legend, options){
              const fitValue = chart.legend.fit;
              chart.legend.fit = () => {
                fitValue.bind(chart.legend)();
                this.height += 1500;
              }
            }
          }],
          aspectRatio: 15 / 3,
          elements: {
            bar: {
              borderWidth: 2,
            }
          },
          legend: {
            position: 'right',
          },
          title: {
            display: true,
          },
          onClick: function(evt, element) {
              if (element.length > 0) {
                  onClickElement(element);
                // you can also get dataset of your selected element
              }},
          scales: {
            r: {
              max: 5,
              min: 0,
              ticks: {
                  stepSize: 5
              }
            },
            x: {
              stacked: true,
              barPercentage: 1.0,
              categoryPercentage: 0.1,
            
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 5,
                suggestedMin: Math.min.apply(null, data) * 0.95,
                suggestedMax: Math.max.apply(null, data) * 1.05
              },
            },
          },
          maintainAspectRatio: false,
          plugins: {
            id: 'legendMargin',
            beforeInit(chart,legend, options){
              const fitValue = chart.legend.fit;
              chart.legend.fit = () => {
                fitValue.bind(chart.legend)();
                return this.height += 1500;
              }
            },
              datalabels: {
                formatter: (value,context) => {
                 return amountConveter(value)
                }
              },
              legendMargin: {
                padding: 50
              },
              subtitle: {
                position: "left",
                display: !!subTitle,
                text: subTitle,
              },
              interaction: {
                mode: 'index',
                intersect: false,
              },
              legend: {
                display: true,
                position: 'bottom'
              },
              legendDistance: {
                padding: 100
              },
              title: {
                position: "top",
                display: !!title,
                text: title,
                offset: 20
              },
          },
      });
      setForceUpdateFunction();
  }, [data]);

    useImperativeHandle(Ref, () => ({
        setForceUpdateFunction
    }));

    const setForceUpdateFunction = () => {
        setForceUpdate(!forceUpdate);
    };


    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Bar height="100%" options={chartOptions} data={chartData} />
        </div>
    );
}
);

export default GroupedBarChart;