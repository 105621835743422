import { useEffect, useState, useRef } from "react";
import { MSFilterType } from "./models/MSFilter.model.ts";
import { MapFiltersComponents } from "./models/MapFiltersComponents.model.ts";
import {
  SearchContainer,
  Button,
  FilterWrapper,
  ContainerButton,
} from "./MSFilters.styled.tsx";
import { useAuth } from "../../UserContext.js";

interface MSFiltersProps {
  filters: MSFilterType[];
  buttonText: string;
  getEndpointGeneral?: () => void;
  initialFormData?: any;
  currentPage?: (e: number) => void;
}

interface FiltersValues {
  [key: string]: string;
}

const MSFilters = ({
  filters,
  buttonText = "Search",
  getEndpointGeneral,
  initialFormData,
  currentPage,
}: MSFiltersProps) => {
  const [clearValue, setClearValue] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<FiltersValues>(
    initialFormData || {},
  );
  const [shouldCallEndpoint, setShouldCallEndpoint] = useState<boolean>(false);
  const { addQueryParams, queryParams, clearQueryParams } = useAuth();

  useEffect(() => {
    if (clearValue) {
      setClearValue(false);
    }
  }, [clearValue]);

  useEffect(() => {
    if (initialFormData) {
      setSearchData(initialFormData || {});
    }
  }, [initialFormData]);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      getEndpointGeneral && getEndpointGeneral();
    }
  }, [queryParams]);

  useEffect(() => {
    if (shouldCallEndpoint && Object.keys(queryParams).length === 0) {
      getEndpointGeneral && getEndpointGeneral();
      setShouldCallEndpoint(false);
    }
  }, [queryParams, shouldCallEndpoint]);

  const handleSearch = () => {
    addQueryParams("", "", false, true, searchData);
  };

  const clearFilters = () => {
    clearQueryParams();
    setSearchData({});
    setClearValue(true);
    setShouldCallEndpoint(true);
    currentPage && currentPage(1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <SearchContainer>
        {filters?.map((filter) => {
          const FilterComponent = MapFiltersComponents[filter.filter_type] || (
            <></>
          );

          return (
            <FilterWrapper key={filter.id} fullWidth={filter.filter_type === 1}>
              {filter?.filter_type === 1 && (
                <FilterComponent
                  placeholder={filter.placeholder}
                  onSearch={(e: string) => {
                    setSearchData((prev) => {
                      const updatedData = { ...prev };
                      if (e === "") {
                        delete updatedData[filter.id];
                      } else {
                        updatedData[filter.id] = e;
                      }

                      return updatedData;
                    });
                  }}
                  id={filter.id}
                  showDebounce={false}
                  clearValue={clearValue}
                  onKeyDown={handleKeyDown}
                />
              )}
              {filter?.filter_type === 3 && (
                <FilterComponent
                  clearValue={clearValue}
                  options={filter.options}
                  onSelect={(e: string) => {
                    setSearchData((prev) => {
                      return {
                        ...prev,
                        [`${filter.id}`]: e,
                      };
                    });
                  }}
                  placeholder={filter.placeholder}
                  id={filter.id}
                />
              )}
              {filter?.filter_type === 4 && (
                <FilterComponent
                  id={filter.id}
                  options={filter.options}
                  value={searchData[filter.id] || []}
                  onChange={(e: string) => {
                    setSearchData((prev) => {
                      if (e === "") return {};
                      return {
                        ...prev,
                        [`${filter.id}`]: e,
                      };
                    });
                  }}
                  placeholder={filter.placeholder}
                  variant={{ display: "tags-below" }}
                />
              )}
            </FilterWrapper>
          );
        })}
      </SearchContainer>
      {filters?.length > 0 && (
        <ContainerButton>
          <div
            className="text__clear"
            onClick={() => {
              clearFilters();
            }}
          >
            Clear filter
          </div>
          <Button
            disabled={Object.keys(searchData).length === 0}
            onClick={() => {
              handleSearch();
              currentPage && currentPage(1);
            }}
          >
            {buttonText}
          </Button>
        </ContainerButton>
      )}
    </>
  );
};

export default MSFilters;
