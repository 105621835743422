import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {Object} props
 * @param {import("./index").TFerState} props.tferState
 * @param {Function} props.onChangeSubdomain
 * @param {Function<string, string>} props.onSubmit
 */
export const SubdomainPicker = ({ tferState, onChangeSubdomain, onSubmit }) => {
  const firstSubdomain = tferState.FETCH_SUBDOMAINS.data?.[0]?.subdomain || "";
  const [selectedSubdomain, setSelectedSubdomain] = useState(firstSubdomain);
  const [apiCredential, setAPICredential] = useState("");

  const hasCredential = () =>
    tferState.FETCH_API_CREDENTIALS_STATUS.data?.status?.has_rest_api_key;

  const canSubmit =
    selectedSubdomain !== "" &&
    (hasCredential() || apiCredential !== "") &&
    tferState.CREATE_API_CREDENTIALS.status !== "pending";

  const handleSubmit = () => {
    onSubmit(apiCredential, selectedSubdomain);
    setAPICredential("");
  };

  return (
    <div className="tfer-app__account-picker">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="tfer-app__line">
          <select
            className={`tfer-app__subdomain-picker-select ${selectedSubdomain === "" ? "tfer-app__is-empty" : ""}`}
            value={selectedSubdomain}
            onChange={(ev) => {
              const { value } = ev.currentTarget;
              setSelectedSubdomain(value);
              onChangeSubdomain(value);
            }}
          >
            <option value="">
              {tferState.FETCH_SUBDOMAINS.status === "success"
                ? "Select a subdomain"
                : "Loading subdomains"}
            </option>
            {tferState.FETCH_SUBDOMAINS.data?.map?.((item) => (
              <option key={item.subdomain} value={item.subdomain}>
                {item.subdomain}
              </option>
            ))}
          </select>
        </div>
        {tferState.isDutonian === false && (
          <div style={{ position: "relative" }}>
            <div className="banner banner-info">
              <div className="banner-content">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="maintenance-banner-icon"
                />
                <span className="banner-message">
                  Contact your Customer Success Manager at PagerDuty to get
                  access to more subdomains.
                </span>
              </div>
            </div>
          </div>
        )}
        {selectedSubdomain !== "" &&
          tferState.FETCH_API_CREDENTIALS_STATUS.status === "success" &&
          !hasCredential() && (
            <div className="tfer-app__line">
              <p className="tfer-app__separated">
                We don't have API credentials for this account, please upload
                one:
                {tferState.CREATE_API_CREDENTIALS.error != null && (
                  <strong style={{ color: "red" }}>
                    {" "}
                    {tferState.CREATE_API_CREDENTIALS.error.message}
                  </strong>
                )}
              </p>
              <div className="InputContainer">
                <div className="InputContainerInner">
                  <input
                    className="InputInput"
                    type="password"
                    autoComplete=""
                    placeholder="Please insert an API key"
                    value={apiCredential}
                    onChange={(ev) => {
                      setAPICredential(ev.currentTarget.value);
                    }}
                  />
                </div>
                <div className="Flex">
                  <div
                    className="InputIcon1"
                    onClick={() => setAPICredential("")}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
              </div>
              <p className="text-align-right tfer-app__caption">
                Using a <b>read-only</b> API key is recommended.
              </p>
            </div>
          )}
        <div className="tfer-app__line" style={{ textAlign: "right" }}>
          <button
            className="tfer-app__btn tfer-app__btn--featured"
            onClick={handleSubmit}
            disabled={canSubmit ? "" : "disabled"}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};
