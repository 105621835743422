import styled, { keyframes } from "styled-components";

const easyIn = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
`;

export const FormInput = styled.input`
  outline: none;
  padding: 16px;
  display: block;
  width: 100%;
  height: 55px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  &:focus {
    border-color: #238ce7;
    box-shadow: 0 0 0 2pt #4a769d66;
  }
`;

export const FormTextArea = styled.textarea`
  outline: none;
  resize: none;
  height: 100px;
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  &:focus {
    border-color: #238ce7;
    box-shadow: 0 0 0 2pt #4a769d66;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  animation: ${easyIn} 3s;
`;

export const PageContainerSides = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 32px;
  animation: ${easyIn} 3s;
  position: relative;
`;

interface PageContainerleftInterface {
  $isOpen: boolean;
}

export const PageContainerLeft = styled.div<PageContainerleftInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.$isOpen ? "calc(100% - 448px)" : "100%")};
  gap: 20px;
  padding: 24px 32px;
  transition: width 0.3s ease-in-out;
`;

export const PageContainerRight = styled.div<PageContainerleftInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 448px;
  gap: 20px;
  transform: ${(props) =>
    props.$isOpen ? "translateX(0)" : "translateX(448px)"};
  padding: 24px 32px;
  background-color: #f9f9f9;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
`;

export const SectionContainer = styled.div`
  margin: 20px;
  padding: 40px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  position: relative;
  height: auto;
  margin-right: 45px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(100vw - 120px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const SectionGrid3x3 = styled.div`
display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
}`;

export const SectionGrid2x2 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
}`;

export const ButtonContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}`;

interface ChevronRowTitleContainerInterface {
  color?: string;
}

export const ChevronRowTitleContainer = styled.div<ChevronRowTitleContainerInterface>`
  color: ${(props) => props.color || "#000"};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0px;
  margin-top: 20px;
`;

interface ChevronRowTitleContainerNoMarginInterface {
  $color?: string;
}

export const ChevronRowTitleContainerNoMargin = styled.div<ChevronRowTitleContainerNoMarginInterface>`
  color: ${(props) => props.$color || "#000"};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0px;
`;

export const ChevronRowTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  margin-left: 24px;
`;

export const ChevronRowIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: #8f8f8f;
  gap: 10px;
  margin-right: 12px;
  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const ReturnIcon = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &:hover {
    color: #a0e0a0;
  }
`;

interface ReturnIconDivInterface {
  $color: string;
}

export const ReturnIconDiv = styled.div<ReturnIconDivInterface>`
  color: ${(props) => props.$color};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

interface ChevronRowContainerTopInterface {
  $isOpen: boolean;
}

export const ChevronRowTitleContainerTop = styled.div<ChevronRowContainerTopInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: ${(props) => (props.$isOpen ? "0px" : "20px")};
  margin-top: 20px;
`;

export const CircleNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 14px;
  background-color: #e9e9e9;
  color: #625f6d;
  border-radius: 50%;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  flex: 1;
  gap: 20px;
`;

interface FormRowInterface {
  $position?: "start" | "center" | "end";
}

export const FormRow = styled.div<FormRowInterface>`
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) => {
    switch (props.$position) {
      case "start":
        return "flex-start";
      case "center":
        return "center";
      case "end":
        return "flex-end";
      default:
        return "flex-start";
    }
  }};
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface FormSpanInterface {
  $mandatory?: string;
}

export const FormLabel = styled.span<FormSpanInterface>`
    margin: 5px 0px;
    text-align: left;
    &:after {
      margin-left: 5px;
        content: ${(props) => (props.$mandatory === "true" ? "'*'" : "''")};
        color: red;
    }s
`;

export const RightContainer = styled.div`
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LayoutContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 150px);
  flex-direction: row;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 400px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
`;

export const ModalButtonsContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

interface ButtonsContainerInterface {
  $position?: "start" | "center" | "end" | "space-between";
}

export const ButtonsContainer = styled.div<ButtonsContainerInterface>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    switch (props.$position) {
      case "start":
        return "flex-start";
      case "center":
        return "center";
      case "end":
        return "flex-end";
      case "space-between":
        return "space-between";
      default:
        return "flex-start";
    }
  }
  };
  gap: 10px;
  width: 100%;
  margin-top: 20px;
`;

export const FormAlert = styled.div`
  font-size: 14px;
  color: #ce0000;
`;

export const SpanIcon = styled.span`
  &:hover {
    color: #2196f3;
  }
`;

interface StatusTagInterface {
  $statusColor: string;
}

export const StatusTag = styled.div<StatusTagInterface>`
  padding: 8px 16px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$statusColor};
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const SpanButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }
`;
