import MultiCheckbox from "./MultiCheckbox";

export const MultiCheckboxSkeleton = () => (
  <div className="tfer-app__multicheckbox-skeleton">
    <div className="tfer-app__multicheckbox-search-skeleton skeleton__animation"></div>
    <div className="tfer-app__multicheckbox-body-skeleton skeleton__animation"></div>
  </div>
);

export const ResourcesPickerSkeleton = () => {
  return (
    <div className="tfer-app__resources-picker">
      {Array.from({ length: 9 }, (x, i) => i).map((k) => (
        <MultiCheckboxSkeleton key={k} />
      ))}
    </div>
  );
};
