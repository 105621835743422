// utils/base64Utils.js

/**
 * Converts a Base64 string to a Blob.
 *
 * @param {string} base64 - The Base64 encoded string.
 * @param {string} [contentType='application/pdf'] - The MIME type of the resulting Blob.
 * @param {number} [sliceSize=512] - The size of the slices to process.
 * @returns {Blob} - The resulting Blob object.
 */
export function base64ToBlob(base64, contentType = 'application/pdf', sliceSize = 512) {
    // Remove any data URL prefix if present.
    if (base64.indexOf('base64,') !== -1) {
      base64 = base64.split('base64,')[1];
    }
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }