import { useState, useMemo } from "react";
import MultiCheckbox from "./MultiCheckbox";
import { RESOURCES_TITLE_TEXT } from "../constants";

/** @param {Object} props
 * @param {import("../index").TFerStateResourceType} props.resourceType
 * @param {import("../index").TFerAsyncAction} props.resources
 * @param {import("../index").TFerDispatchSetPlan} props.setPlan
 */
export const ResourcePicker = ({ resourceType, resources, setPlan }) => {
  const resource = useResource(resourceType, resources, setPlan);

  return <MultiCheckbox {...resource} />;
};

const OPTION_VALUE_FN = {};
const OPTION_VALUE_DEFAULT_FN = (item) => item.id;

const OPTION_LABEL_FN = {};
const OPTION_LABEL_DEFAULT_FN = (item) => item.summary;

const toOption = (key, data) =>
  Object.keys(data == null ? {} : data).map((id) => {
    let valueFn = OPTION_VALUE_FN[key];
    if (typeof valueFn !== "function") {
      valueFn = OPTION_VALUE_DEFAULT_FN;
    }
    let labelFn = OPTION_LABEL_FN[key];
    if (typeof labelFn !== "function") {
      labelFn = OPTION_LABEL_DEFAULT_FN;
    }
    const value = valueFn(data[id]);
    let label = labelFn(data[id]);
    if (label == null) {
      label = value;
    }
    return { value, label };
  });

/**
 * @param {string} key
 * @param {import("./index").TFerAsyncAction} action
 * @param {import("./index").TFerDispatchSetPlan} setPlan
 */
const useResource = (key, action, setPlan) => {
  if (action == null) action = {};
  const options = useMemo(() => toOption(key, action.data), [key, action.data]);
  return {
    label: RESOURCES_TITLE_TEXT[key],
    disabled: (action.data?.length || 0) > 0 && action.status !== "success",
    options,
    onChange: (checked) =>
      setPlan(key)(checked.map((id) => ({ ...action.data[id] }))),
    status: action.status,
    // tooltip: TOOLTIP_TEXT[key],
  };
};
