import { useRef, useState, useEffect } from 'react';
import MultiCheckbox from '@/components/MultiCheckbox';

interface Option {
  entity_id: number;
  entity_name: string;
}

interface EntityMultiCheckboxProps {
  handleSelection?: (value: any[]) => void;
  initialOptions: Option[];
  label?: string;
  width?: string;
  disabled?: boolean;
  disabledItem?: boolean;
}

export const EntityMultiCheckbox = ({
  initialOptions,
  handleSelection,
  label = 'Filter by Entity',
  width = '20%',
  disabled,
  disabledItem,
}: EntityMultiCheckboxProps) => {
  const dropdownRef = useRef(null);

  const [filterState, setFilterState] = useState<any[]>([]);
  const [searchState, setsearchObjectType] = useState<string>('');
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>(initialOptions);

  const createFilteredOptions = (
    options: Option[],
    searchState: string,
    field: string
  ) => {
    return options?.filter((option: any) =>
      (field ? option[field] : option)
        ?.toLowerCase()
        .includes(searchState?.toLowerCase())
    );
  };

  const filteredOptions: Option[] = createFilteredOptions(
    options,
    searchState,
    'entity_name'
  );

  const handleDropdownClick = () => setIsOpenDropdown(!isOpenDropdown);

  const toggleCheckboxAll = async (item: any, setState: any) => {
    setState((prevState: any) => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState?.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  const toggleCheckbox = (entities: any) => {
    toggleCheckboxAll(entities, setFilterState);
  };

  useEffect(() => {
    if (handleSelection) {
      handleSelection(filterState);
    }
  }, [filterState]);

  return (
    <MultiCheckbox
      label={label}
      options={options}
      filterState={filterState}
      setFilterState={setFilterState}
      isOpenDropdown={isOpenDropdown}
      searchState={searchState}
      setSearchState={setsearchObjectType}
      filteredOptions={filteredOptions}
      handleDropdownClick={handleDropdownClick}
      toggleCheckbox={toggleCheckbox}
      mapFunction={(item: Option) => item?.entity_id}
      nameLabel={(item: Option) => item?.entity_name}
      dropdownRef={dropdownRef}
      disabled={disabled || !filteredOptions?.length > 0}
      disabledItem={
        disabledItem || filterState.length >= filteredOptions?.length
      }
      width={width}
    />
  );
};

export default EntityMultiCheckbox;
