import Auth from "./routes";
import React, { useEffect, useState } from "react";
import { sessionModal } from "./services/subjects";
import AuthorizationModal from "./components/AuthorizationModal/AuthorizationModal";
import './App.css';
import { analytics } from "./utils/analytics";
import { useLocation } from "react-router-dom";


analytics
  .init({
    writeKey: process.env.REACT_APP_SEGMENT_APIKEY,
    debug: process.env.NODE_ENV === 'test',
  })
  .catch((error) => {
    console.error('Failed to initialize analytics:', error);
  });

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const subscription = sessionModal.subscribe({
      next: (isOpen) => {
        setIsModalOpen(isOpen);
      }
    });

    // Unsubscribe on component unmount
    return () => {
      subscription.unsubscribe();
    };
  }, []);

// Navigation tracking
useEffect(() => {
  if (analytics.isInitialized()) {
    // Track navigation when location changes
    analytics.trackNavigation(location.pathname, {
      previousPath: location.state?.from || '',
      search: location.search,
      hash: location.hash,
      timestamp: new Date().toLocaleString('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }),
      modalState: isModalOpen ? 'open' : 'closed',
      // Add any additional properties you want to track
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    });
  }
}, [location, isModalOpen]);

  return (
    <div style={{ height: '100%' }}>
      {isModalOpen && <AuthorizationModal />}
     <Auth/>
    </div>
  );
}

export default App;





