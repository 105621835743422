export const ANALYTICS_EVENTS = {
  SOLUTION_GALLERY: {
    SEARCH_SOLUTION_GALLERY:"SG Search Keywords",
    CLASSIFICATION_SOLUTION_GALLERY: "SG Classification Filter",
    PRODUCT_AREA_FILTER_SOLUTION_GALLERY:"SG Product Area Filter",
    MOST_REQUESTED_CART_SOLUTION_GALLERY: "SG Most Requested",
    CART_SOLUTION_GALLERY: "SG Cart Solution Gallery",
    CLEAR_CART_SOLUTION_GALLERY: "SG Clear Cart",
    NAVIGATE_NAVBAR_SOLUTION_GALLERY: "SG Navigate from  Navbar",
    VIEW_DETAILS_MOST_REQUESTED_SOLUTION_GALLERY:"SG Most Requested View details",
    VIEW_DETAILS_SRA_SOLUTION_GALLERY:"SG SRA View details",
    NAVIGATE_ALERTS_SOLUTION_GALLERY: "SG Navigate from  Alerts",
    SUBMIT_CART_SOLUTION_GALLERY: "SG Submit Cart",
  }
};