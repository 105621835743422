import React from "react";

export class TerraformerErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Implement error tracking service call here
    console.error({ error_Terraformer_ErrorBoundary: error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: "100vh",
            flexDirection: "column",
          }}
          className="tfer-app--flex tfer-app--flex-align-center"
        >
          <h1>Something went wrong</h1>
          <p>
            We are sorry, but an error occurred while displaying the{" "}
            <strong>Terraformations Factory app</strong> try reloading the page.
          </p>
          <p>
            If the problem persists, please contact us at{" "}
            <a
              href="https://pagerduty.enterprise.slack.com/archives/CMUHTKHS5"
              target="_blank"
            >
              <strong>#terraform_provider</strong>
            </a>{" "}
            Slack channel.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
