export const STATUS_IDLE = "idle";
export const STATUS_PENDING = "pending";
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR = "error";

export const RESOURCES = Object.freeze({
  services: "services",
  users: "users",
  escalationPolicies: "escalation_policies",
  servicesDependencies: "services_dependencies",
  servicesIntegrations: "services_integrations",
  schedules: "schedules",
  addons: "addons",
  alertGroupingSettings: "alert_grouping_settings",
  automationActions: "automation_actions",
  automationActionsRunners: "automation_actions_runners",
  automationActionsRunnersTeams: "automation_actions_runners/teams",
  automationActionsServices: "automation_actions/services",
  automationActionsTeams: "automation_actions/teams",
  businessServices: "business_services",
  businessServicesSubscribers: "business_services/subscribers",
  extensions: "extensions",
  incidentCustomFields: "incident_custom_fields",
  incidentTypes: "incident_types",
  incidentTypesCustomFields: "incident_types/custom_fields",
  incidentWorkflows: "incident_workflows",
  incidentWorkflowsTriggers: "incident_workflows_triggers",
  jiraCloudAccountMappingRules: "jira_cloud_account_mapping_rules",
  maintenanceWindows: "maintenance_windows",
  orchestrations: "orchestrations",
  orchestrationsGlobal: "orchestrations/global",
  orchestrationsGlobalCacheVariables: "orchestrations/global_cache_variables",
  orchestrationsIntegrations: "orchestrations/integrations",
  orchestrationsRouter: "orchestrations/router",
  orchestrationsUnrouted: "orchestrations/unrouted",
  rulesets: "rulesets",
  rulesetsRules: "rulesets/rules",
  servicesEventRules: "services/event_rules",
  servicesOrchestrations: "services/orchestrations",
  servicesOrchestrationsCacheVariables: "services/orchestrations_cache_variables",
  tags: "tags",
  tagsAssignments: "tags/assignments",
  teams: "teams",
  teamsMemberships: "teams/memberships",
  usersContactMethods: "users/contact_methods",
  usersHandoffNotificationRules: "users/handoff_notification_rules",
  usersNotificationRules: "users/notification_rules",
  webhookSubscriptions: "webhook_subscriptions",
});

/**
 * This map is the invertion of RESOURCES. It allows us to access the properties
 * from tferState.resources from the resourceType received from the back-end.
 */
export const RESOURCES_KEYS_FOR_STATE = Object.freeze(
  Object.keys(RESOURCES).reduce(
    (memo, k) => ({ ...memo, [RESOURCES[k]]: k }),
    {},
  ),
);

export const RESOURCES_TITLE_TEXT = Object.freeze({
  addons: "Add-ons",
  alertGroupingSettings: "Alert Grouping Settings",
  automationActions: "Automation Actions",
  automationActionsRunners: "Automation Actions Runners",
  automationActionsRunnersTeams: "Automation Actions Runners Teams Associations",
  automationActionsServices: "Automation Actions Services Associations",
  automationActionsTeams: "Automation Actions Teams Associations",
  businessServices: "Business Services",
  businessServicesSubscribers: "Business Services Subscribers",
  escalationPolicies: "Escalation Policies",
  extensions: "Extensions",
  incidentCustomFields: "Incident Custom Fields",
  incidentTypes: "Incident Types",
  incidentTypesCustomFields: "Incident Types Custom Fields",
  incidentWorkflows: "Incident Workflows",
  incidentWorkflowsTriggers: "Incident Workflows Triggers",
  jiraCloudAccountMappingRules: "Jira Cloud Account Mapping Rules",
  maintenanceWindows: "Maintenance Windows",
  orchestrations: "Event Orchestrations",
  orchestrationsGlobal: "Global Event Orchestrations",
  orchestrationsGlobalCacheVariables: "Global Event Orchestrations Cache Variables",
  orchestrationsIntegrations: "Integrations for an Event Orchestration",
  orchestrationsRouter: "Event Orchestrations Routers",
  orchestrationsServices: "Event Orchestrations Services",
  orchestrationsServicesCacheVariables: "Event Orchestrations Services Cache Variables",
  orchestrationsUnrouted: "Event Orchestrations Unrouted Routers",
  rulesets: "Rulesets",
  rulesetsRules: "Rulesets Rules",
  schedules: "Schedules",
  services: "Technical services",
  servicesDependencies: "Services Dependencies",
  servicesEventRules: "Services Event Rules",
  servicesIntegrations: "Services Integrations",
  servicesOrchestrations: "Services Orchestrations",
  servicesOrchestrationsCacheVariables: "Services Orchestrations Cache Variables",
  tags: "Tags",
  tagsAssignments: "Tags Assignments",
  teams: "Teams",
  teamsMemberships: "Teams Memberships",
  users: "Users",
  usersContactMethods: "Users Contact Methods",
  usersHandoffNotificationRules: "Users Handoff Notification Rules",
  usersNotificationRules: "Users Notification Rules",
  webhookSubscriptions: "Webhook Subscriptions",
});

const addSummaryToBusinessServicesSubscribers = (tferState) => (data) =>
  data.map((item) => {
    const parts = item.id.split(".");
    const bsId = parts[0];
    const sType = parts[1];
    const bsSummary = tferState.resources.businessServices.data[bsId]?.summary;
    let sSummary = parts[2];
    switch (sType) {
      case "team":
        sSummary = "Team " + tferState.resources.teams.data[parts[2]]?.summary;
        break;
      case "user":
        sSummary = "User " + tferState.resources.users.data[parts[2]]?.summary;
        break;
      default:
        break;
    }
    const summary = sSummary + " for " + bsSummary;
    return { ...item, summary };
  });

const addSummaryToIncidentTypes = (tferState) => (data) =>
  data.map((item) => {
    return {
      ...item,
      html_url: `https://${tferState.customerSubdomain}.pagerduty.com/incidents/types/${item.id}/fields`,
    };
  });

const addSummaryToIncidentTypesCustomFields = (tferState) => (data) =>
  data.map((item) => {
    const [typeId, fieldId] = item.id.split(":");
    console.log({ item, typeId, fieldId });
    return {
      ...item,
      html_url: `https://${tferState.customerSubdomain}.pagerduty.com/incidents/types/${typeId}/fields/${fieldId}`,
    };
  });

const addSummaryToOrchestrations = (tferState) => (data) =>
  data.map((item) => ({
    ...item,
    summary: tferState.resources.orchestrations.data[item.id]?.summary,
  }));

const addSummaryToServicesOrchestrations = (tferState) => (data) =>
  data.map((item) => {
    const found = tferState.resources.services.data[item.id];
    return {
      ...item,
      summary:
        found?.summary ||
        (item.id && `Service orchestration for service ${item.id}`),
      html_url:
        found?.id &&
        `https://${tferState.customerSubdomain}.pagerduty.com/event-orchestration/service/${found.id}`,
    };
  });

const addSummaryToRulesetsRules = (tferState) => (data) =>
  data.map((item) => {
    const [rulesetId, ruleId] = item.id.split(".");
    return {
      ...item,
      summary: `Rule ${ruleId} for ${tferState.resources.rulesets.data[rulesetId]?.summary || `Ruleset ${rulesetId}`}`,
    };
  });

/**
 * @param {import("./index").TFerState} tferState
 */
const addSummaryToServicesDependencies = (tferState) => (data) =>
  data.map((item) => {
    const [supId, supType, depId] = item.id.split(".");

    const supSummary = (function () {
      if (
        supType === "technical_service" ||
        supType === "technical_service_reference"
      ) {
        return `Service "${tferState.resources.services.data[supId]?.summary || supId}"`;
      }
      if (
        supType === "business_service" ||
        supType === "business_service_reference"
      ) {
        return `Business Service "${tferState.resources.businessServices.data[supId]?.summary || supId}"`;
      }
      return `${supId}.${supType}`;
    })();

    const depSummary = (function () {
      const businessService =
        tferState.resources.businessServices.data[depId]?.summary;
      if (businessService != null) {
        return `Business Service "${businessService}"`;
      }
      const service = tferState.resources.services.data[depId]?.summary;
      if (service != null) {
        return `Service "${service}"`;
      }
      return depId;
    })();

    const summary = `${supSummary} uses ${depSummary}`;

    return { ...item, summary };
  });

export const RESOURCES_DATA_PATCH_FN = Object.freeze({
  businessServicesSubscribers: addSummaryToBusinessServicesSubscribers,
  incidentTypes: addSummaryToIncidentTypes,
  incidentTypesCustomFields: addSummaryToIncidentTypesCustomFields,
  orchestrationsGlobal: addSummaryToOrchestrations,
  orchestrationsRouter: addSummaryToOrchestrations,
  orchestrationsUnrouted: addSummaryToOrchestrations,
  rulesetsRules: addSummaryToRulesetsRules,
  servicesDependencies: addSummaryToServicesDependencies,
  servicesOrchestrations: addSummaryToServicesOrchestrations,
});

export const TFER_API_BASE_URL =
  [
    {
      url: "localhost",
      api: "http://localhost:8080/api/v1",
    },
    {
      url: "portal-test.innovation.csg.pagerduty.com",
      api: "https://backend-test.innovation.csg.pagerduty.com/ms-terraformer/api/v1",
    },
    {
      url: "portalstg.innovation.csg.pagerduty.com",
      api: "https://backend-stg.innovation.csg.pagerduty.com/ms-terraformer/api/v1",
    },
    {
      url: "portal.innovation.csg.pagerduty.com",
      api: "https://backend.innovation.csg.pagerduty.com/ms-terraformer/api/v1",
    },
  ].find((env) => env.url === window.location.hostname)?.api || "";
