import { useState, useMemo } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_ERROR, STATUS_PENDING, STATUS_IDLE } from "../constants";

/** @param {import("../index").MultiCheckboxProps} props
 */
const MultiCheckbox = ({ label, disabled, options, onChange, status }) => {
  const [checked, setChecked] = useState([]);

  const [search, setSearch] = useState("");
  // Options displayed in the selector
  const filtered = useMemo(
    () =>
      options.filter((o) =>
        o.label?.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, options],
  );

  const allSelected = options.length > 0 && checked.length === options.length;

  const handleToggleCheckbox = (value) => (ev) => {
    if (disabled) {
      return;
    }
    ev.stopPropagation();
    const ix = checked.indexOf(value);
    let newState = [];
    if (ix >= 0) {
      newState = [...checked.slice(0, ix), ...checked.slice(ix + 1)];
    } else {
      newState = [...checked, value];
    }
    setChecked(newState);
    onChange(newState);
  };

  const handleSelectAll = (ev) => {
    if (disabled) {
      return;
    }
    ev.stopPropagation();
    const newState = allSelected ? [] : options.map((o) => o.value);
    setChecked(newState);
    onChange(newState);
  };

  return (
    <div className="container__filterAlerts">
      <div className="dropdown__clients">
        <div className="container__tooltipMultiCheckbox">
          <div className="label__inputFilter">
            {label}
            {(status === STATUS_IDLE || status === STATUS_PENDING) && (
              <>
                {" "}
                <FontAwesomeIcon className="tfer-app__spin" icon={faSpinner} />
              </>
            )}
          </div>
          <label className="tfer-app__select-all">
            <input
              type="checkbox"
              checked={allSelected}
              onChange={handleSelectAll}
            />{" "}
            Select all
          </label>
        </div>

        <div className="dropdown-menuMyAlerts__wrap">
          <input
            className="search__subdomainHomeFilter"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
          <div className="dropdown-menuMyAlerts">
            <ul>
              {(filtered?.length || 0) > 0 &&
                filtered.map((item) => (
                  <li key={item.value}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checked.includes(item.value)}
                        onChange={handleToggleCheckbox(item.value)}
                        disabled={disabled}
                      />
                      <span className="customer_name">{item.label}</span>
                    </label>
                  </li>
                ))}
              {options.length === 0 &&
                !(status === STATUS_IDLE || status === STATUS_PENDING) && (
                  <p style={{ color: "#afafaf" }}>No items</p>
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiCheckbox;
