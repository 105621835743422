import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { apiRoutes, apiRequest } from './../services';
import './Layout.css';
import {
  notificationSubject,
  feedbackSubject,
  configurationSubject,
  bannerSubject,
} from '../services/subjects';
import StatusMessages from './StatusMessages';
import Jwt from '../utils/jwt';
import Modal from '../components/Modal';
import Banner from '../components/Banner';
import Menu from '../components/Menu/index.js';
import Navbar from '../components/Navbar/index.js';
import ThankYou from '../components/ThankYou';
import TerribleFace from '../assets/SVGs/Faces/terribleFace';
import BadFace from '../assets/SVGs/Faces/badFace';
import OkayFace from '../assets/SVGs/Faces/okayFace';
import GoodFace from '../assets/SVGs/Faces/goodFace';
import AmazingFace from '../assets/SVGs/Faces/amazingFace';
import {
  faFileMedicalAlt,
  faChartLine,
  faChessQueen,
  faExclamationTriangle,
  faInfoCircle,
  faFilePowerpoint,
  faShip,
  faBook,
  faSwatchbook,
  faCompass,
  faImage,
  faBell,
  faUser,
  faWarning,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import Notification from './Notifications/Notification';
import Paths from '@/paths.js';
import { useAuth } from "../UserContext";
import { checkPortalAlertMyAlerts } from "../pages/MyAlertsApp/utils/my-alerts.util";

const Layout = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userOptions, setUserOptions] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [bannerProps, setBannerProps] = useState(null);
  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState(null);
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [thankYouState, setThankYouState] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});
  const { profile, profileSet } = useAuth();

  const [menuItems, setMenuItems] = useState([
    {
      sectionLabel: '',
      items: [
        {
          icon: 'Home',
          label: 'Dashboard',
          description: 'See your Dashboard',
          appName: 'SolutionGallery',
          to: '/',
        },
        {
          icon: 'AdminIcon',
          label: 'Administration',
          description: 'template Administration',
          appName: 'AdministrationApp',
          to: '/users',
        },
        {
          icon: 'HealthIcon',
          label: 'Health Check',
          description:
            'Automated account health checks featuring deep discovery, analysis and recommendations. Analyzes best practices, maturity and attributes of CSG’s Early Warning Score (EWS) and provides actionable recommendations for customer, account and services teams.',
          appName: 'HealthCheckApp',
          to: '/health-check-client',
        },
        {
          icon: 'OnboardingIcon',
          label: 'OnBoarding',
          description:
            'Automated Ready for Duty Onboarding application makes onboarding teams of up to 30 a breeze. A complete, Ready for Duty Team includes best practice team, responder, escalation policy, schedule, and response play configurations completed in minutes.',
          appName: 'OnBoardingApp',
          to: '/onboarding',
        },
        {
          icon: 'DataViewerIcon',
          label: 'Health Check Data Viewer',
          description: 'A simple app to Help SOD team talk with clients.',
          appName: 'HealthCheckDataViewer',
          to: '/health-check-data-viewer',
        },
        {
          icon: 'Deck',
          label: 'Deck Generator',
          description: 'A simple app to Generate PPT and send over email.',
          appName: 'NextDeckGen',
          to: '/deck-generator',
        },
        {
          icon: 'Solution',
          label: 'Solution Gallery',
          description: 'Solution Gallery',
          appName: 'SolutionGallery',
          to: '/solution-gallery',
        },
        {
          icon: 'Alerts',
          label: 'Health Check Alert App',
          description: 'A simple app to Generate PPT and send over email.',
          appName: 'MyAlertsApp',
          to: '/my-alerts-app/team',
        },
        {
          icon: 'SnapshotIcon',
          label: 'Customer snapshots',
          description: 'A simple app to Generate PPT and send over email.',
          appName: 'CustomerSnapshots',
          to: '/customer-snapshots',
        },
        {
          icon: 'TerraformationIcon',
          label: 'Terraformations Factory',
          description:
            "Generate Terraform configuration files for your PagerDuty account resources.",
          appName: "TerraformerApp",
          to: "/terraformer",
        },
        {
          icon: 'LearningHubIcon',
          label: "Learning Hub",
          description:
            "A place where all the knowledge and docs you need to get started.",
          appName: "LearningHub",
          to: "/learning-hub",
        },
      ],
    },
  ]);
  const pathname = window.location.pathname;

  const StatusMessagesRef = useRef();

  const getMenu = () => {
    let menu = tokenData.claims.roles.map((role) => {
      return role.apps.map((app) => {
        return { appName: app.app };
      });
    });
    setUserOptions(menu[0]);
    setIsLoading(false);
  };

  const getProfile = async () => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.userProfile,
    });
    if (res) {
      profileSet(res.data);
    }
  };

  useEffect(() => {
    const userAllowedApps = userOptions?.map((app) => app.appName);
    if (userAllowedApps) {
      const newMenuItems = menuItems.map((section) => {
        section.items = section.items.filter(
          (item) =>
            userAllowedApps.includes(item.appName) ||
            userAllowedApps.includes('Admin')
        );
        return section;
      });
      setMenuItems(newMenuItems);
    }
  }, [userOptions]);

  const getFeatureFlag = async () => {
    const [error, res] = await apiRequest({
      method: 'get',
      url: apiRoutes.featureFlags,
    });
    if (res) {
      setFeatureFlags(res.features);
    }
  };

  useEffect(() => {
    getFeatureFlag();
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      featureFlags &&
      featureFlags.portal_alert &&
      featureFlags.portal_alert !== ''
    ) {
      bannerSubject.next({
        // message: 'The team is currently making updates related to the monthly release, and some features in the portal may be unavailable and the estimated time to release those changes is 1 to 3 hours.',
        message: featureFlags.portal_alert,
        type: 'error',
        icon: faWarning,
      });
    } else if (
      featureFlags &&
      checkPortalAlertMyAlerts(featureFlags.portal_alert_my_alerts, profile)
    ){
      bannerSubject.next({
        message: featureFlags.portal_alert_my_alerts.message,
        type: 'warning-modern',
        icon: faExclamationTriangle,
      });
    } else {
      bannerSubject.next(null);
    }
  }, [featureFlags, profile]);

  useEffect(() => {
    if (tokenData !== null) {
      getMenu();
    }
    setTokenData(Jwt.getItem('all'));
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'));
      getProfile();
      setTokenData(Jwt.getItem('all'));
    } else if (tokenData !== null) {
      getMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notificationSubscription = notificationSubject.subscribe(
      (notification) => {
        StatusMessagesRef?.current.appendNewMessage({
          text: notification.message,
          type: notification.type,
        });

        bannerSubject.next({
          message: notification.message,
          type: notification.type.toLowerCase(),
          icon:
            notification.type === 'WARNING'
              ? faExclamationTriangle
              : faInfoCircle,
        });
      }
    );
    const feedbackSubscription = feedbackSubject.subscribe(() => {
      setModalOpen(true);
    });

    return () => {
      notificationSubscription.unsubscribe();
      feedbackSubscription.unsubscribe();
    };
  }, []);

  function onProfileClick() {
    setActive(!active);
  }

  const closeFeedbackButton = async () => {
    setModalOpen(false);
    setFeedbackDescription('');
    setFeedbackRating(null);
  };

  const submitFeedbackButton = async () => {
    setThankYouState(true);
    await apiRequest({
      method: 'post',
      url: apiRoutes.userFeedback,
      data: {
        comments: feedbackDescription,
        rating: feedbackRating,
        page_reference: feedbackNameLiteral.hasOwnProperty(pathname)
          ? feedbackNameLiteral[pathname]()
          : 'Portal',
        pathname,
      },
    });
    setTimeout(() => {
      setThankYouState(false);
      setModalOpen(false);
      setFeedbackDescription('');
      setFeedbackRating(null);
    }, 5000);
  };
  const feedbackNameLiteral = {
    '/': () => 'Portal',
    '/home': () => 'Portal',
    '/success-on-demand': () => 'Success on Demand App',
    '/onboarding': () => 'Onboarding App',
    '/users': () => 'Administration App',
    '/health-check': () => 'Health Check App',
    '/health-check-client': () => 'Health Check App',
    '/health-check-data-viewer': () => 'Health Check Data Viewer',
    '/migration-wizard': () => 'Migration Visualization App',
    '/alerts-administration': () => 'Alerts Administration',
    '/my-alerts-app': () => 'My Alerts App',
    '/customer-snapshots': () => 'Customer snapshots',
  };

  return (
    <div style={{ height: '100%' }}>
      <div className='Layout'>
        <div className='content'>
          <div className='page-container'>
            <div className='page'>
              <div className='content-wrapper'>
                <div className='side-menu'>
                  <Menu
                    basePath=''
                    menuItems={menuItems}
                  />
                </div>
                <div className='main-content'>
                  <Navbar menuItems={menuItems} />
                  <div className='outlet-wrapper'>
                    <Banner />
                    <Outlet />
                  </div>
                </div>
              </div>
              <Notification />
              <StatusMessages ref={StatusMessagesRef} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalOpen}
        title='Feedback'
        SpecialClassName='FeedbackModal'
        handleClose={() => closeFeedbackButton()}
        modalFitContent
      >
        {thankYouState ? (
          <div className='container__thankYou'>
            <ThankYou />
          </div>
        ) : (
          <>
            <label>
              How would you rate your experiences with the{' '}
              <span className='feedbackSpan'>
                {feedbackNameLiteral.hasOwnProperty(pathname)
                  ? feedbackNameLiteral[pathname]()
                  : 'Portal'}
              </span>
              ?
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <button
                onClick={() => setFeedbackRating(1)}
                style={
                  feedbackRating === 1
                    ? {
                        border: '1px solid rgb(6, 172, 56)',
                        color: 'rgb(6, 172, 56)',
                      }
                    : {}
                }
                className='feedbackButton'
              >
                <TerribleFace scale='0.2' />
                Terrible
              </button>
              <button
                onClick={() => setFeedbackRating(2)}
                style={
                  feedbackRating === 2
                    ? {
                        border: '1px solid rgb(6, 172, 56)',
                        color: 'rgb(6, 172, 56)',
                      }
                    : {}
                }
                className='feedbackButton'
              >
                <BadFace scale='0.2' />
                Bad
              </button>
              <button
                onClick={() => setFeedbackRating(3)}
                style={
                  feedbackRating === 3
                    ? {
                        border: '1px solid rgb(6, 172, 56)',
                        color: 'rgb(6, 172, 56)',
                      }
                    : {}
                }
                className='feedbackButton'
              >
                <OkayFace scale='0.2' />
                Okay
              </button>
              <button
                onClick={() => setFeedbackRating(4)}
                style={
                  feedbackRating === 4
                    ? {
                        border: '1px solid rgb(6, 172, 56)',
                        color: 'rgb(6, 172, 56)',
                      }
                    : {}
                }
                className='feedbackButton'
              >
                <GoodFace scale='0.2' />
                Good
              </button>
              <button
                onClick={() => setFeedbackRating(5)}
                style={
                  feedbackRating === 5
                    ? {
                        border: '1px solid rgb(6, 172, 56)',
                        color: 'rgb(6, 172, 56)',
                      }
                    : {}
                }
                className='feedbackButton'
              >
                <AmazingFace scale='0.2' />
                Amazing
              </button>
            </div>
            <div className='feedbackTextareaDiv'>
              <label>
                Do you have any feedback or comments on improving the{' '}
                <span className='feedbackSpan'>
                  {feedbackNameLiteral.hasOwnProperty(pathname)
                    ? feedbackNameLiteral[pathname]()
                    : 'Portal'}
                </span>
                ?
              </label>
              <textarea
                value={feedbackDescription}
                onChange={(e) => setFeedbackDescription(e.target.value)}
              />
            </div>
            <div className='feedbackButtonDiv'>
              <button
                className='feedbackSubmitButton'
                onClick={() => submitFeedbackButton()}
                disabled={!feedbackRating || !feedbackDescription}
              >
                Submit
              </button>
              <button
                className='feedbackCloseButton'
                onClick={() => closeFeedbackButton()}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Layout;
