import styled from "styled-components";

export const Contenedor = styled.div`
  display: flex;
  width: 100%;
  color: #252525;
`;

export const ContenedorCarousel = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-flow: wrap;
  justify-content: right;
`;
export const Licenses = styled.div`
  align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 3px 0;
  h3 {
    font-size: 24px;
  }
`;
export const LicenseData = styled.div`
	padding: 0 6px;
	font-size: 12px;
  overflow-x: auto;
  p {
    margin-bottom: 0px;
  }
`;
export const ProductCard = styled.div`
    background-color: #fafafa;
    width: 100%;
    text-align: center;
    font-size: 12px;
    height: auto;
    margin: 8px 0;
    border-radius: 16px;
    border-left: 3px solid #137170;
    display: flex;
    padding: 24px;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
`;

export const ContenedorApps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ContenedorItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  row-gap: 14px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #1a73e8;
  }
`;

export const ContenedorItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  column-gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  flex-flow: column;
`;
export const RegularTitle = styled.h3`
  font-size: 18px;
  &.digits-6 {
    font-size: 18px;
  }
  &.digits-7 {
    font-size: 16px;
  }
  &.digits-8, &.digits-9, &.digits-10 {
    font-size: 13px;
  }
  &.digits-11, &.digits-12, &.digits-13, &.digits-14 {
    font-size: 10px;
  }
`;
export const MainCardTitle = styled.h3`
   font-size: 18px;
   text-align: left;
   margin-bottom: 0px;
`;
export const TitleBox = styled.div`
  display: flex;
  align-items: left;
  flex-flow: column;
  justify-content: left;
`;
export const LabelTitle = styled.div`
  display: flex;
  h3 {
    align-items: center;
    background-color: #117170;
    border-bottom: 0;
    border-radius: 4px;
    color: white;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin: 0;
    padding: 6px;
    width: fit-content;
  }
`;
export const CarouselButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 0;
  &:disabled{
    background-color: transparent !important;
    cursor: not-allowed;
  }
`;

export const CarouselIcon = styled.div`
  font-size: 30px;
`;

export const BarChartContainer = styled.div`
  margin: 0px 0px;
`;