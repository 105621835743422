import { lightGreen } from "@mui/material/colors";
import Jwt from "./jwt";


interface NavigationPath {
  from: string;
  to: string;
  timestamp: string;
}

interface NavigationHistory {
  paths: NavigationPath[];
  currentPath: string;
}

interface RouteTimer {
  startTime: number;
  path: string;
}

interface RouteDuration {
  path: string;
  duration: number;
  timestamp: string;

  
}


export type Properties = Record<string, any>;

export interface AnalyticsEvent {
  name: string;
  properties?: Properties;
}

export interface AnalyticsUser {
  id: string;
  traits?: Properties;
}

export interface AnalyticsOptions {
  writeKey: string;
  debug?: boolean;
}

declare global {
  interface Window {
    analytics: {
      track(event: string, properties?: Properties): void;
      page(name?: string, properties?: Properties): void;
      identify(userId: string, traits?: Properties): void;
      reset(): void;
      load(writeKey: string): void;
      ready(callback: () => void): void;
      debug(enabled: boolean): void;
    };
  }
}

class Analytics {
  private initialized = false;
  private navigationHistory: NavigationHistory = {
    paths: [],
    currentPath: ''
  };
  private currentRoute: RouteTimer | null = null;
  private routeDurations: RouteDuration[] = [];
  private readonly MAX_PATH_HISTORY = 10;
  private calculateTotalRouteDuration(): { totalDuration: number; totalDurationSeconds: number } {
    const totalDuration = this.routeDurations.reduce((sum, route) => sum + route.duration, 0);
    return {
      totalDuration,
      totalDurationSeconds: Math.floor(totalDuration / 1000)
    };
  }


  init(options: AnalyticsOptions): Promise<void> {
    if (this.initialized) return Promise.resolve();

    const hostname = window.location.hostname;
    // Define los writeKeys y scripts según el ambiente
    const envConfigs: any = {
      "localhost": {
        writeKey: options.writeKey,
        script: this.getLegacySegmentScriptTest
      },
      "portal-test.innovation.csg.pagerduty.com": {
        writeKey: options.writeKey,
        script: this.getLegacySegmentScriptTest
      },
      "portalstg.innovation.csg.pagerduty.com": {
        writeKey: options.writeKey,
        script: this.getNewSegmentScriptStg
      },
      "portal.innovation.csg.pagerduty.com": {
        writeKey: options.writeKey,
        script: this.getNewSegmentScriptPro
      }
    };
  
    const config = envConfigs[hostname]

    return new Promise((resolve, reject) => {
      try {
        const script = document.createElement("script");
        script.innerHTML = config.script(config.writeKey);
  
        document.head.appendChild(script);
  
        if (options.debug) {
          window.analytics.debug(true);
        }
  
        window.analytics.ready(() => {
          this.initialized = true;
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  

  getLegacySegmentScriptTest(writeKey: string) {
    return `
      !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics._writeKey="${writeKey}";analytics.SNIPPET_VERSION="4.15.3";
      analytics.load("${writeKey}");
      analytics.page();
      }}();
    `;
  }
  

  getNewSegmentScriptStg(writeKey: string) {
    return `
      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${writeKey}";analytics.SNIPPET_VERSION="5.2.0";
      analytics.load("${writeKey}");
      analytics.page();
      }}();
    `;
  }

  getNewSegmentScriptPro(writeKey: string) {
    return `
        !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="YOUR_API_KEY";;analytics.SNIPPET_VERSION="5.2.0";
        analytics.load("${writeKey}");
        analytics.page();
        }}();
    `;
  }
  

  private ensureInitialized(): void {
    if (!this.initialized) {
      console.warn('Analytics not initialized. Call init() first.');
    }
  }

  private startRouteTimer(path: string) {
    this.currentRoute = {
      startTime: Date.now(),
      path
    };
  }

  private endRouteTimer() {
    if (this.currentRoute) {
      const duration = Date.now() - this.currentRoute.startTime;
      const routeDuration: RouteDuration = {
        path: this.currentRoute.path,
        duration,
        timestamp: new Date().toISOString()
      };

      this.routeDurations.push(routeDuration);
      
      this.track({
        name: 'Route Duration',
        properties: {
          path: this.currentRoute.path,
          duration,
          durationInSeconds: Math.floor(duration / 1000),
          timestamp: new Date().toISOString()
        }
      });

      this.currentRoute = null;
    }
  }

  track(event: AnalyticsEvent): void {
    this.ensureInitialized();
    const token = Jwt.getItem("all");

    try {
      const updatedProperties = {
        ...event.properties,
        userId: token?.name || '',
        timestamp: new Date().toISOString()
      };
      window.analytics?.track(event.name, updatedProperties);
    } catch (error) {
      console.error(`Error tracking event '${event.name}':`, error);
    }
  }

  trackNavigation(to: string, properties?: Properties): void {
    this.ensureInitialized();
    const token = Jwt.getItem("all");
    const from = this.navigationHistory.currentPath;
    const timestamp = new Date().toISOString();
  
    try {
      // End the timer for the current route and start a new one.
      this.endRouteTimer();
      this.startRouteTimer(to);
  
      const navigationPath: NavigationPath = { from, to, timestamp };
      this.navigationHistory.paths.push(navigationPath);
      if (this.navigationHistory.paths.length > this.MAX_PATH_HISTORY) {
        this.navigationHistory.paths.shift();
      }
      this.navigationHistory.currentPath = to;
  
      const navigationProperties = {
        ...properties,
        userId: token?.name || '',
        from,
        to,
        timestamp,
        pathHistory: this.navigationHistory.paths,
        navigationDepth: this.navigationHistory.paths.length,
        routeDurations: this.getRouteDurations()
      };
  
      // Track the general navigation path.
      window.analytics?.track('Navigation Path', navigationProperties);
      if (to === '/logout') {
        this.trackUserFlowOnLogout();
      }
  
      // Build page properties.
      const pageProperties: Properties = {
        ...properties,
        previousPath: from,
        navigationTimestamp: timestamp
      };
  
      // If the previous path is '/login', add additional properties, We need to test this.
      if (from === '/login') {
        let enteredFromLoginDate = timestamp;
        if (this.navigationHistory.paths.length > 1) {
          // Exclude the current event (last item in paths)
          for (let i = this.navigationHistory.paths.length - 2; i >= 0; i--) {
            if (this.navigationHistory.paths[i].to === '/login') {
              enteredFromLoginDate = this.navigationHistory.paths[i].timestamp;
              break;
            }
          }
        }
        pageProperties.enteredFromLogin = true;
        pageProperties.enteredFromLoginDate = enteredFromLoginDate;
      }
  
      this.page(to, pageProperties);
    } catch (error) {
      console.error('Error tracking navigation:', error);
    }
  }
  
  private trackUserFlowOnLogout(): void {
    this.ensureInitialized();
    const token = Jwt.getItem("all");

    const routeDurationSummary = this.calculateTotalRouteDuration();

    try {

      const flowProperties = {
        userId: token?.name || '',
        sessionSummary: {
          endTime: new Date().toISOString(),
        },
        pathSequence: this.getPathsForCurrentSession(),
        totalPaths: this.navigationHistory.paths.length,
        uniquePaths: new Set(this.navigationHistory.paths.map(p => p.to)).size,
        averageTimeSpent: this.calculateAverageTimeSpent(),
        routeDurations: this.getRouteDurations(),
        loginLogoutSummary: {
          totalDurationMs: routeDurationSummary.totalDuration,
          totalDurationSeconds: routeDurationSummary.totalDurationSeconds,
        },
        timestamp: new Date().toISOString()
      };

      window.analytics?.track('Logout User Flow', flowProperties);
      // Reset login timestamp after logout
    } catch (error) {
      console.error('Error tracking logout user flow:', error);
    }
  }
  reset(): void {
    if (this.initialized) {
      window.analytics?.reset();
    }
  }

  private getPathsForCurrentSession(): string[] {
    // Find the index of the most recent '/login' event by iterating backwards.
    let lastLoginIndex = -1;
    for (let i = this.navigationHistory.paths.length - 1; i >= 0; i--) {
      if (this.navigationHistory.paths[i].to === '/login') {
        lastLoginIndex = i;
        break;
      }
    }
    const sessionPaths = lastLoginIndex !== -1 
      ? this.navigationHistory.paths.slice(lastLoginIndex) 
      : this.navigationHistory.paths;
  
    return sessionPaths.map(path => path.to);
  }

  private calculateAverageTimeSpent(): number {
    if (this.navigationHistory.paths.length < 2) return 0;

    let totalTime = 0;
    for (let i = 1; i < this.navigationHistory.paths.length; i++) {
      const current = new Date(this.navigationHistory.paths[i].timestamp);
      const previous = new Date(this.navigationHistory.paths[i - 1].timestamp);
      totalTime += current.getTime() - previous.getTime();
    }

    return totalTime / (this.navigationHistory.paths.length - 1);
  }


  getRouteDurations(): RouteDuration[] {
    return this.routeDurations;
  }

  page(name?: string, properties?: Properties): void {
    this.ensureInitialized();
    try {
      window.analytics?.page(name, properties);
    } catch (error) {
      console.error(`Error tracking page '${name}':`, error);
    }
  }

  identify(user: AnalyticsUser): void {
    this.ensureInitialized();
    try {
      window.analytics?.identify(user.id, user.traits);
    } catch (error) {
      console.error(`Error identifying user '${user.id}':`, error);
    }
  }

  isInitialized(): boolean {
    return this.initialized;
  }
}

export const analytics = new Analytics();
