import { severityLegend } from '@/pages/MyAlertsApp/utils';
import {
  Dot,
  AlertSeverityLegendList,
  AlertSeverityLegendItem,
} from './AlertSeverityLegend.styled';

export const AlertSeverityLegend = () => {
  return (
    <AlertSeverityLegendList>
      {severityLegend?.map((item, index) => {
        return (
          <AlertSeverityLegendItem key={index}>
            <span>{item.severity}</span>
            <Dot
              key={index}
              bgColor={item.bgColor}
            />
          </AlertSeverityLegendItem>
        );
      })}
    </AlertSeverityLegendList>
  );
};

export default AlertSeverityLegend;
