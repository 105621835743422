import styled from 'styled-components';

export const darkenColor = (color: string, percent: number): string => {
  color = color.replace('#', '');

  if (!/^[0-9A-Fa-f]{6}$/.test(color)) {
    throw new Error('Invalid color format. Expected a 6-digit hex color.');
  }

  let r: number = parseInt(color.slice(0, 2), 16);
  let g: number = parseInt(color.slice(2, 4), 16);
  let b: number = parseInt(color.slice(4, 6), 16);

  r = Math.max(0, Math.floor(r * (1 - percent)));
  g = Math.max(0, Math.floor(g * (1 - percent)));
  b = Math.max(0, Math.floor(b * (1 - percent)));

  return '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');
};

export const HeatmapContainer = styled.div`
  display: grid;
  gap: 24px;
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 24px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 24px;
  }
`;

export const HeatmapEntityAlertGrid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 129px 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const HeatmapGrid = styled.ul`
  display: grid;
  gap: 4px;
  grid-auto-rows: minmax(111px, auto);
  grid-template-columns: repeat(auto-fill, minmax(229px, 1fr));
  margin: 0;
  width: 100%;
`;

interface HeatmapCardProps {
  bgColor?: string;
  textColor?: string;
}

export const HeatmapCard = styled.li<HeatmapCardProps>`
  align-items: center;
  background-color: ${({ bgColor = '#ffffff' }) => bgColor};
  color: ${({ textColor = '#000000' }) => textColor};
  cursor: pointer;
  display: grid;
  font-size: 14px;
  margin: 0;
  min-height: 100px;
  padding: 16px;
  text-align: start;
  transition: transform 0.2s, box-shadow 0.2s;

  p {
    line-height: 21px;
    margin: 0;
  }

  span {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-top: auto;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: ${({ bgColor = '#ffffff' }) => darkenColor(bgColor, 0.1)};
  }
`;

interface CenteredMessageProps {
  width?: string;
}

export const CenteredMessage = styled.p<CenteredMessageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  max-width: ${({ width = '400px' }) => width};
  margin: 0 auto;
`;
