
import {
  BUTTON_TABS
} from "../../utils/constants.js";
import { useNavigate, useLocation  } from "react-router-dom";
import Copyright from "../../components/Copyright"
import ContentTabs from '../../components/ContentTabs'
import { ROUTE_MAP } from "../../utils/constants.js";


export default function Layout({children}) {
  const navigate = useNavigate();
  const location = useLocation();


  const onChangeCurrentSession = (item) => {
    navigate(item)
  }


  return(
    <div>
      <div className="">
          <ContentTabs
            tabs={BUTTON_TABS}
            activeTab={ROUTE_MAP[location.pathname] || 'team'}
            onTabClick={onChangeCurrentSession}
          />
      </div>
      <>{children}</>
      <div className="container__copy"><Copyright /></div>
    </div>
  )
}