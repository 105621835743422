import React, { useState, useEffect } from 'react';
import   Modal  from "../../../components/Modal";
import { Button, PDInput, Tiptap } from '@/components';
import {
  ResourceContainer,
  ResourceTitle,
  ContainerLink,
  GhostButton,
  ReleaseStyle,
  ButtonNewReleaseSve,
  ContentTitle,
  FlexInputGroup,
  ModalContainerFlex
} from '../LearningHub.styled';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { apiRequest, apiRoutes } from '../../../services';


interface Resource {
  file_name: string;
  link: string;
}

interface AppTabData {
  name: string,
  title: string,
  description: string,
  resources: Resource[]
}

const ModalNewTabApp: React.FC<Props> = ({showModalTabApp, setShowModalTabApp, getApplications}) => {
  const [tabName, setTabName] = useState<string>('');
  const [contentTitle, setContentTitle] = useState<string>('Terminology');
  const [contentDescription, setContentDescription] = useState<string>('Example Content Description');
  const [resources, setResources] = useState<Resource[]>([
    { file_name: '', link: '' }
  ]);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    const validateForm = () => {
      if (!tabName || !contentTitle || !contentDescription) {
        setIsValid(false);
        return;
      }

      const areResourcesValid = resources.every(
        resource => resource.file_name.trim() !== '' && resource.link.trim() !== ''
      );

      setIsValid(areResourcesValid);
    };

    validateForm();
  }, [tabName, contentTitle, contentDescription, resources]);


  const addResource = () => {
    setResources([...resources, { file_name: '', link: '' }]);
  };

  const removeResource = (index: number) => {
    const newResources = resources.filter((_, i) => i !== index);
    setResources(newResources);
  };

  const updateResource = (index: number, field: keyof Resource, value: string) => {
    const newResources = resources.map((resource, i) => {
      if (i === index) {
        return { ...resource, [field]: value };
      }
      return resource;
    });
    setResources(newResources);
  };

  const handleSubmit = async () => {
    const tabData: AppTabData = {
      name: tabName || 'New Tab',
      title: contentTitle,
      description: contentDescription,
      resources: resources
    };


    try {
      const [error, data] = await apiRequest({
        method: 'post',
        url: apiRoutes.applications,
        data: tabData,
      });
  

      if (data) {
        getApplications && getApplications()
        setShowModalTabApp(false);
        setIsValid(false);
        
      }
    } catch (error) {
      console.error('Error adding new tab:', error);
    }
  };

  return(
    <Modal
      show={showModalTabApp}
      handleClose={() => setShowModalTabApp(false)}
      title={"Add New Application"}
    >
      <ModalContainerFlex>
      <FlexInputGroup>
        <ContentTitle>Tab Name</ContentTitle>
        <PDInput
            value={tabName}
            onChange={(value) => setTabName(value)}
          />
      </FlexInputGroup>
      <FlexInputGroup>
        <ContentTitle>Content Title</ContentTitle>
        <PDInput
          value={contentTitle}
          onChange={(value) => setContentTitle(value)}
        />
      </FlexInputGroup>
      <FlexInputGroup>
          <ContentTitle>Content Description</ContentTitle>
          <Tiptap
            onChange={(value) => setContentDescription(value)}
            initialContent={contentDescription}
          />
        </FlexInputGroup>
        <ReleaseStyle>
          <ContainerLink>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <ResourceTitle>Resources</ResourceTitle>
              <div>
                <GhostButton
                  onClick={addResource}
                  $variant='bordered'
                >
                  Add Resource{' '}
                  <PlusIcon width={24} height={24} />
                </GhostButton>
              </div>
            </div>

            {resources.map((resource, index) => (
              <ResourceContainer key={index}>
                <PDInput
                  value={resource.file_name}
                  onChange={(newValue) => updateResource(index, 'file_name', newValue)}
                  placeholder="Resource Name"
                />
                <PDInput
                  value={resource.link}
                  onChange={(newValue) => updateResource(index, 'link', newValue)}
                  placeholder="Resource Link"
                />
                <GhostButton
                  onClick={() => removeResource(index)}
                  disabled={resources.length === 1}
                >
                  <TrashIcon width={24} height={24} />
                </GhostButton>
              </ResourceContainer>
            ))}
          </ContainerLink>
        </ReleaseStyle>
        <ButtonNewReleaseSve>
          <Button
            onClick={handleSubmit}
            type='primary'
            text='Save'
            icon='CheckIcon'
            disabled={!isValid}
          />
        </ButtonNewReleaseSve>
      </ModalContainerFlex>
    </Modal>
  );
};

export default ModalNewTabApp;