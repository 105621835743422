import styled from "styled-components";
import { TabsTypes } from "./Tabs.model";

interface TabsContainerInterface {
  $activeProp?: boolean;
  $type: TabsTypes;
  $position?: "left" | "right" | "center";
  $paddingBottom?: boolean;
}

export const TabsContainer = styled.div<TabsContainerInterface>`
  display: flex;
  justify-content: ${(props) => props.$position === "center" && "center" || props.$position === "left" && "flex-start" || "flex-end"};
  border-bottom: ${(props) => (props.$type === "color" ? "0px" : "1px solid #e9e9e9")};
  height: ${(props) => (props.$type === "border" ? "40px" : props.$paddingBottom ? "60px" : "40px")};
  padding-bottom: ${(props) => (props.$type === "border" ? "0px" : props.$paddingBottom ? "16px" : "0px")};
`;

export const TabsStyled = styled.div<TabsContainerInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  border: ${(props) => (props.$type === "border" ? "1px solid" : "none")};
  border-bottom: ${(props) =>
    props.$type === "border" ? "none" : "2px solid"};
  color: ${(props) =>
    props.$activeProp
      ? props.$type === "color"
        ? "#117170"
        : "#000"
      : "#646464"};
  border-color: ${(props) =>
    props.$activeProp
      ? props.$type === "color"
        ? "#117170"
        : "#E9E9E9"
      : "transparent"};
  height: 41px;
  background-color: ${(props) => (props.$activeProp ? "#fff" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  gap: 8px;

  & svg {
  height: 20px;
  width: 20px;
  }
`;