import styled from 'styled-components';

export const AlertSeverityLegendList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  gap: 24px;
`;

export const AlertSeverityLegendItem = styled.li`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  width: fit-content;

  span {
    font-size: 14px;
  }
`;

export const Dot = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  height: 16px;
  width: 33px;
`;
