import React, { useState, useEffect, useReducer } from "react";
import "./Terraformer.css";
import { apiRequest, apiRoutes } from "../../services";
import Modal from "../../components/Modal";
import { SubdomainPicker } from "./SubdomainPicker";
import { ResourcesPicker } from "./ResourcesPicker";
import { TerraformationPlan } from "./TerraformationPlan";
import {
  faListCheck,
  faRightLeft,
  faQuestionCircle,
  faStarHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TerraformationProgress } from "./TerraformationProgress";
import Copyright from "../../components/Copyright";
import Questions from "../../components/Questions";
import Jwt from "../../utils/jwt";
import {
  RESOURCES,
  RESOURCES_DATA_PATCH_FN,
  RESOURCES_KEYS_FOR_STATE,
  STATUS_IDLE,
  STATUS_SUCCESS,
  TFER_API_BASE_URL,
} from "./constants";

import { ResourcesPickerSkeleton } from "./ResourcesPicker/Skeleton";

/** @type {import("./index").AsyncAction} */
const asyncActionDefault = {
  status: STATUS_IDLE,
  data: {},
  error: null,
};

const TFER_ASYNC_ACTIONS = {
  CREATE_API_CREDENTIALS: "CREATE_API_CREDENTIALS",
  FETCH_API_CREDENTIALS_STATUS: "FETCH_API_CREDENTIALS_STATUS",
  FETCH_FAQS: "FETCH_FAQS",
  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_SUBDOMAINS: "FETCH_SUBDOMAINS",
  FETCH_RESOURCE_TYPES: "FETCH_RESOURCE_TYPES",
};

const TFER_ACTIONS = {
  FETCH_RESOURCES: "FETCH_RESOURCES",
  FETCH_RESOURCES_SUCCESS: "FETCH_RESOURCES_SUCCESS",
  FETCH_RESOURCES_ERROR: "FETCH_RESOURCES_ERROR",
  SET_TERRAFORMATION_PLAN: "SET_TERRAFORMATION_PLAN",
  RESET_TERRAFORMATION_PLAN: "RESET_TERRAFORMATION_PLAN",
  SET_TERRAFORMATION_MODE: "SET_TERRAFORMATION_MODE",
  SET_CUSTOMER_SUBDOMAIN: "SET_CUSTOMER_SUBDOMAIN",
  RESET_STATE: "RESET_STATE",
};

const UI_PROGRESS_STATE = {
  RUN_INITIAL_CHECKS: "RUN_INITIAL_CHECKS",
  DISPLAY_ACCOUNT_PICKER: "DISPLAY_ACCOUNT_PICKER",
  DISPLAY_RESOURCES_PICKER: "DISPLAY_RESOURCES_PICKER",
  DISPLAY_TERRAFORMATION_PLAN: "DISPLAY_TERRAFORMATION_PLAN",
  DISPLAY_TERRAFORMATION_PROGRESS: "DISPLAY_TERRAFORMATION_PROGRESS",
  DISPLAY_TERRAFORMATION_RESULTS: "DISPLAY_TERRAFORMATION_RESULTS",
};

const blockingErrorCommonMessage =
  "Error while fetching functional application data. We are working on it. Please try again later.";
const blockingErrorMessagesList = {
  fetching_customer_api_credentials_status: blockingErrorCommonMessage,
};

/** @returns {import("./index").TFerState} */
function createInitialState() {
  return {
    terraformationMode: null,

    terraformationPlan: Object.keys(RESOURCES).reduce((plan, res) => {
      return {
        ...plan,
        [res]: [],
      };
    }, {}),

    resources: Object.keys(RESOURCES).reduce((resources, res) => {
      return {
        ...resources,
        [res]: {
          ...asyncActionDefault,
        },
      };
    }, {}),

    ...Object.keys(TFER_ASYNC_ACTIONS).reduce(
      (memo, value) => ({
        ...memo,
        [value]: { ...asyncActionDefault },
      }),
      {},
    ),

    isPremiumTerraformer:
      Jwt.getItem("all")?.claims?.roles?.some((role) => {
        if (role.role === "Admin") {
          return true;
        }
        const hasTerraformerWrite = role.apps.find(
          (a) => a.app === "TerraformerApp" && a.permissions.includes("WRITE"),
        );
        return role.role === "Premium Csg Member" && hasTerraformerWrite;
      }) || false,

    isDutonian: null,

    customerSubdomain: "",
  };
}

/** @param {import("./index").TFerState} state
 * @param {import("./index").TFerAction} action
 * @returns {import("./index").TFerState}
 */
function tferReducer(state, action) {
  switch (action.type) {
    case TFER_ACTIONS.SET_TERRAFORMATION_MODE:
      return {
        ...state,
        terraformationMode: action.data,
      };
    case TFER_ACTIONS.SET_TERRAFORMATION_PLAN:
      return reducerSetTerraformationPlan(
        state,
        action.data.key,
        action.data.update,
      );

    case TFER_ACTIONS.SET_CUSTOMER_SUBDOMAIN:
      return { ...state, customerSubdomain: action.data };

    case TFER_ACTIONS.FETCH_RESOURCES:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            ...state.resources[action.resourceType],
            status: "pending",
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "success",
            data: action.data?.reduce((memo, item) => {
              memo[item.id] = item;
              return memo;
            }, {}),
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "error",
            error: action.error,
          },
        },
      };

    case TFER_ASYNC_ACTIONS.FETCH_PROFILE + "_SUCCESS":
      // Additional reducer case used to update isDutonian. Don't return so it
      // can be catch again in the iteration through TFER_ASYNC_ACTIONS.
      state = {
        ...state,
        isDutonian:
          action.data == null ||
          action.data?.data?.customer_company_name === "PagerDuty",
      };
      break;

    case TFER_ASYNC_ACTIONS.FETCH_SUBDOMAINS + "_SUCCESS":
      if (action.data?.length === 1) {
        state = { ...state, customerSubdomain: action.data[0].subdomain };
      }
      break;

    case TFER_ASYNC_ACTIONS.FETCH_RESOURCE_TYPES + "_SUCCESS":
      {
        const available = { ...action.data };
        Object.keys(RESOURCES_KEYS_FOR_STATE).forEach((key) => {
          delete available[key];
        });
        if (Object.keys(available).length > 0) {
          console.warn(
            "Not using available resources:",
            Object.keys(available),
          );
        }
      }
      break;

    case TFER_ASYNC_ACTIONS.CREATE_API_CREDENTIALS + "_RESET":
      return {
        ...state,
        [TFER_ASYNC_ACTIONS.CREATE_API_CREDENTIALS]: { ...asyncActionDefault },
      };

    case TFER_ACTIONS.RESET_TERRAFORMATION_PLAN:
      const { terraformationPlan } = createInitialState();
      return { ...state, terraformationPlan };

    case TFER_ACTIONS.RESET_STATE:
      return createInitialState();

    default:
      break;
  }

  for (let key in TFER_ASYNC_ACTIONS) {
    if (TFER_ASYNC_ACTIONS.hasOwnProperty(key)) {
      switch (action.type) {
        case key:
          return {
            ...state,
            [key]: { data: null, error: null, status: "pending" },
          };
        case key + "_SUCCESS":
          return { ...state, [key]: { ...action, status: "success" } };
        case key + "_ERROR":
          return { ...state, [key]: { ...action, status: "error" } };
        default:
          break;
      }
    }
  }

  return state;
}

/** @param {import("./index").TFerReducerState} state
 * @param {string} key
 * @param {import("./index").PagerDutyResourceData[]} update
 */
function reducerSetTerraformationPlan(state, key, update) {
  return {
    ...state,
    terraformationPlan: {
      ...state.terraformationPlan,
      [key]: update,
    },
  };
}

// @param {Object} options
// @param {string} options.url
// @param {Object} options.data
// @param {string} options.dataType
// @return {[Error, Object, Response]} options.url
async function apiRequestTfer(options) {
  let err, respData, resp;
  try {
    const { url, data } = options;
    delete options.url;
    delete options.data;

    if (options.headers == null) {
      options.headers = {};
    }
    options.headers["Authorization"] =
      `CsgPDBearer ${localStorage.getItem("token")}`;
    options.headers["Content-Type"] = "application/json";

    try {
      options.body = JSON.stringify(data);
    } catch {}

    resp = await fetch(TFER_API_BASE_URL + url, options);
    respData = await resp.json();
    if (!resp.ok) {
      throw respData;
    }
  } catch (e) {
    err = e;
  }

  return [err, respData, resp];
}

export function Terraformer() {
  const [displayUIState, setDisplayUIState] = useState(
    UI_PROGRESS_STATE.RUN_INITIAL_CHECKS,
  );
  const [blockingErrorMessage, setBlockingErrorMessage] = useState(null);
  const [terraformationRunId, setTerraformationRunId] = useState("");

  /** @type {['normal' | 'switch' | null, function(boolean): void]} */
  const [showTerraformationModeButton, setShowTerraformationModeButton] =
    useState(null);

  /** @type {[import("./index").TFerState, import("./index").TFerDispatch]} */
  const [tferState, dispatch] = useReducer(
    tferReducer,
    null,
    createInitialState,
  );

  const [isRedirectingToPDIdentity, setIsRedirectingToPDIdentity] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTittle, setModalTittle] = useState("");
  /** @type {[null | 'small', (modalType: null | 'small') => void]} */
  const [modalType, setModalType] = useState(null);
  /** @type {['error' | 'faqs' | 'oauth-redirect', (contentOf: 'error' | 'faqs' | 'oauth-redirect') => void]} */
  const [modalDisplayContentOf, setModalDisplayContentOf] = useState("error");
  /** @type {[null | 'success' | 'error', (result: null | 'success' | 'error') => void]} */
  const [oauthRedirectResult, setOauthRedirectResult] = useState(null);
  const [oauthRedirectMessage, setOauthRedirectMessage] = useState("");
  const [canAutoContinueToResources, setCanAutoContinueToResources] =
    useState(true);

  // Probably we should move this along with the State Reducer to a context
  /** @type {[import("./index").TFerAppRemoteConfigMap, function(): void]} */
  const [tferAppRemoteConfig, setTFerAppRemoteConfig] = useState(null);

  const hasRESTAPIKey =
    tferState.FETCH_API_CREDENTIALS_STATUS.status === STATUS_SUCCESS &&
    tferState.FETCH_API_CREDENTIALS_STATUS.data?.status.has_rest_api_key;

  const getCustomerAPICredentialsStatus = async (subdomain) => {
    const [error, data] = await apiRequestTfer({
      method: "GET",
      url: `/customers/api-credentials-status?customer_subdomain=${subdomain}`,
    });
    if (error || !data || !data.status) {
      console.error({
        error_Terraformer_getCustomerAPICredentialsStatus: error,
      });
      setBlockingErrorMessage(
        blockingErrorMessagesList.fetching_customer_api_credentials_status,
      );
      return [error, null];
    }
    return [null, data];
  };

  const createAPICredentials = async (apiCredential, subdomain) => {
    const [error, data] = await apiRequestTfer({
      method: "POST",
      url: `/api-credentials?customer_subdomain=${subdomain}`,
      data: {
        rest_api_key: apiCredential,
        customer_subdomain: subdomain,
      },
    });
    // TODO: check 201 response, it's not a json so the parsing of the error
    // response is delayed until here
    return [error, data];
  };

  /** @returns {Promise<[Error, import("./index").TFerAPIResponse]>} */
  const getTFerAppRemoteConfig = async () => {
    const [error, data] = await apiRequestTfer({
      method: "GET",
      url: "/remote-config",
    });
    if (error) {
      return [error, null];
    }
    const remoteConfig = data.remote_config;
    return [null, remoteConfig];
  };

  /**
   * @param {string} resourceType
   * @param {string[]} [ids]
   * @returns {Promise<[Error, import("./index").PagerDutyResourceData[]]>} */
  const getResourceWithType = async (resourceType, ids) => {
    let url = `/${RESOURCES[resourceType]}?customer_subdomain=${tferState.customerSubdomain}`;
    if (Array.isArray(ids)) {
      url += `&ids=${ids.join(",")}`;
    }

    const [error, resp] = await apiRequestTfer({ method: "GET", url });
    if (error || !resp) {
      return [error, null];
    }

    /** @type import("./index").PagerDutyResourceData[] */
    // const data = resp[key == null ? path : key];
    return [null, resp.resources];
  };

  /** @returns {Promise<[Error|null, import("./index").FAQ[]]>} */
  const getFAQs = async () => {
    const [error, data] = await apiRequestTfer({ method: "GET", url: "/faqs" });
    if (error || !data) {
      return [error, null];
    }
    return [null, data.faqs];
  };

  /**
   * @param {string} premiumSubdomain
   * @returns {Promise<[Error|null, import("./index").Subdomain[]]>}
   */
  const fetchSubdomains = async (premiumSubdomain) => {
    let url = `/subdomains?customer_subdomain=${premiumSubdomain}`;
    if (tferState.isDutonian) {
      const email = Jwt.getItem("all")?.sub;
      if (email != null) {
        url += `&email=${email}`;
      }
    }
    const [error, data] = await apiRequestTfer({ method: "GET", url });
    if (error || data == null) {
      return [error, null];
    }
    return [null, data.subdomains];
  };

  /** @returns {Promise<[Error|null, import("./index").Profile]>} */
  const fetchProfile = async () => {
    const [error, data] = await apiRequest({
      method: "GET",
      url: `${apiRoutes.userProfile}`,
    });
    if (data instanceof Response && data.status === 204) {
      return [error, null];
    }
    return [error, data];
  };

  const fetchResourceTypes = async () => {
    return await apiRequestTfer({
      method: "GET",
      url: "/resource_types",
    });
  };

  /** @type {import("./index").RequestTerraformation} */
  const requestTerraformation = async (everything, plan) => {
    /** @type {string[] | undefined} */
    let byType;
    if (everything) {
      byType = Object.keys(RESOURCES).map((k) => RESOURCES[k]);
    }

    const [error, data] = await apiRequestTfer({
      method: "POST",
      url: `/terraformations?customer_subdomain=${tferState.customerSubdomain}`,
      data: {
        by_type: byType,
        resources: plan,
      },
    });
    if (error || data.run_id === "") {
      console.error({ error_Terraformer_requestTerraformation: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    setTerraformationRunId(data.run_id);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS);
  };

  /** @type {import("./index").GetTerraformationStatus} */
  const getTerraformationStatus = async (runId) => {
    const [error, data, response] = await apiRequestTfer({
      method: "GET",
      url: `/terraformations/${runId}?customer_subdomain=${tferState.customerSubdomain}`,
    });

    if (response.status === 404) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "not_found";
    }
    if (response.status === 500) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "failed";
    }

    if (error || !data) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    return data.status;
  };

  /** @type {import("./index").GetTerraformationLogs} */
  const getTerraformationLogs = async (runId) => {
    /** @type Response | null **/
    let response = null;
    /** @type Object | null **/
    let error = null;

    try {
      const path = `/terraformations/${runId}/log?customer_subdomain=${tferState.customerSubdomain}`;
      response = await fetch(`${TFER_API_BASE_URL}${path}`, {
        method: "get",
        headers: {
          Authorization: `CsgPDBearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      error = await response.json();
    }

    if (error != null || response == null) {
      console.error({ error_Terraformer_getTerraformationLogs: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    const data = await response.text();
    return data;
  };

  /** @type {import("./index").DownloadTerraformCode} */
  const downloadTerraformCode = async (runId) => {
    /** @type Response | null **/
    let response = null;
    /** @type Object | null **/
    let error = null;

    try {
      const path = `/terraformations/${runId}/download?customer_subdomain=${tferState.customerSubdomain}`;
      response = await fetch(`${TFER_API_BASE_URL}${path}`, {
        method: "get",
        headers: {
          Authorization: `CsgPDBearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      error = await response.json();
    }

    if (error != null) {
      console.error({ error_Terraformer_downloadTerraformCode: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    const contentDisposition = response?.headers.get("content-disposition");
    if (response == null || contentDisposition == null) {
      console.error({
        error_Terraformer_downloadTerraformCode: {
          message: "response does not have a Content-Disposition",
        },
      });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    const filename = contentDisposition
      .split(";")[1]
      .split("=")[1]
      .replace(/^"|"$/g, "");
    const contentType = response.headers.get("content-type");
    const bytes = Uint8Array.from(atob(await response.text()), (c) =>
      c.charCodeAt(0),
    );
    var blob = new Blob([bytes], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  /*
   * Declare all dispatcher/selectors here
   */

  /**
   * @param {import("./index").TFerStateResourceType} resourceType
   * @param {string} path
   * @returns {Promise<void>}
   */
  const dispatchGetResources = async (resourceType) => {
    dispatch({ type: TFER_ACTIONS.FETCH_RESOURCES, resourceType });

    const parentType =
      tferState.FETCH_RESOURCE_TYPES.data[RESOURCES[resourceType]][0];

    /** @type {string[] | undefined} */
    let ids;
    if (parentType != null) {
      const key = RESOURCES_KEYS_FOR_STATE[parentType];
      ids = Object.keys(tferState.resources[key].data);
    }

    const [error, data] = await getResourceWithType(resourceType, ids);
    if (error) {
      console.error({
        error_Terraformer_dispatchGetResources: error,
        resourceType,
      });
      return dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_ERROR,
        resourceType,
        error,
      });
    }

    let patchedData = data;

    const dataPatchingFn = RESOURCES_DATA_PATCH_FN[resourceType];
    if (data && dataPatchingFn) {
      patchedData = dataPatchingFn(tferState)(data);
    }

    if (data) {
      dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_SUCCESS,
        resourceType,
        data: patchedData,
      });
    }
  };

  /**
   * @param {string} actionType
   * @param {Function<[Error|null, any]>} call
   */
  const dispatchHelper = async (actionType, call) => {
    dispatch({ type: actionType });
    const [error, data] = await call();
    if (error) {
      console.error({ ["error_Terraformer_dispatch_" + actionType]: error });
      return dispatch({ type: actionType + "_ERROR", error: error });
    }
    return dispatch({ type: actionType + "_SUCCESS", data });
  };

  /** @type {import("./index").TFerDispatchSetPlan} */
  const dispatchSetTerraformationPlan = (key) => (update) => {
    dispatch({
      type: TFER_ACTIONS.SET_TERRAFORMATION_PLAN,
      data: { key, update },
    });
  };

  /** @param {import("./index").TerraformationMode} mode
   * @returns {void}
   */
  const dispatchSetTerraformationMode = (mode) => {
    dispatch({ type: TFER_ACTIONS.SET_TERRAFORMATION_MODE, data: mode });
  };

  /*
   * Declare all helper functions here
   */
  const renderUISubtitleContent = (uiState) => {
    const uiStateContentMap = {
      [UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER]: (
        <>
          <p>
            Generate the Terraform configuration files of your PagerDuty account
            with a few clicks.
          </p>
        </>
      ),
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: (
        <p>
          Generate the whole Terraform configuration files of your PagerDuty
          resources by clicking on the button{" "}
          <strong className="tfer-app__nowrap">Terraform Everything</strong>, or
          select the specific ones you plan to manage via Terraform from the
          list below and click on{" "}
          <strong className="tfer-app__nowrap">Terraformation Plan</strong>.
          {/* <p>Terraformation App is running initial checks...</p> */}
        </p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: (
        <p>
          Generate the whole Terraform configuration files of your PagerDuty
          resources by clicking on the button{" "}
          <strong className="tfer-app__nowrap">Terraform Everything</strong>, or
          select the specific ones you plan to manage via Terraform from the
          list below and click on{" "}
          <strong className="tfer-app__nowrap">Terraformation Plan</strong>.
        </p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: (
        <p>
          Terraform configuration files written in{" "}
          <code className="tfer-keyword">HCL</code> will be generated for the
          PagerDuty resources shown below. To confirm this action click on{" "}
          <strong className="tfer-app__nowrap">Generate Terraform Code</strong>{" "}
          button. This is a read&#x2011;only operation and it cannot produce
          changes in your PagerDuty account until you approve and apply the
          files using <code className="tfer-keyword">terraform</code>.
        </p>
      ),
    };
    return uiStateContentMap[uiState];
  };

  const renderFeedbackRequestInline = () => {
    const feedbackRequestFormUrl =
      tferAppRemoteConfig?.["feedback-request-form-url"]?.value;
    if (!feedbackRequestFormUrl) return null;

    return (
      <span style={{ marginLeft: 5, fontSize: 14, color: "#016080" }}>
        (
        <a
          style={{ fontSize: 14, color: "#016080", textDecoration: "none" }}
          title="Got feedback? We're listening"
          href={feedbackRequestFormUrl}
          target="_blank"
          rel="noreferrer"
        >
          Got feedback?
          <FontAwesomeIcon
            icon={faStarHalfStroke}
            style={{ marginLeft: 3, verticalAlign: "0.01em" }}
          />
        </a>
        )
      </span>
    );
  };

  const addNewSubdomain = () => {
    setIsRedirectingToPDIdentity(true);
    const pdIdentityUrl = tferAppRemoteConfig?.["pd-identity-url"]?.value;

    if (!pdIdentityUrl) {
      return;
    }
    window.location.href = pdIdentityUrl;
  };

  const cleanPage = () => {
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER);
    setBlockingErrorMessage(null);
    setIsModalOpen(false);
    setModalDisplayContentOf("error");
    setModalTittle("");
    setModalType(null);
    setTerraformationRunId("");
    setShowTerraformationModeButton(null);
    dispatch({ type: TFER_ACTIONS.RESET_STATE });
    // IMPORTANT: don't reset setCanAutoContinueToResources(false) as we want
    // users to return to account picker to onboard new subdomains.
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openModalError = () => {
    setModalDisplayContentOf("error");
    setModalTittle("Error");
    setModalType("small");
    openModal();
  };

  const openModalFAQs = () => {
    setModalDisplayContentOf("faqs");
    setModalTittle("FAQ");
    setModalType(null);
    openModal();
  };

  const openModalOAuthRedirect = () => {
    setModalDisplayContentOf("oauth-redirect");
    setModalTittle("PagerDuty Sandbox account registration result");
    setModalType("small");
    openModal();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeErrorModal = () => {
    cleanPage();
    closeModal();
  };

  /** @param {import("./index").TerraformationMode} mode */
  const displayTerraformationPlan = (mode) => {
    dispatchSetTerraformationMode(mode);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN);
  };

  /*
   * Declare all useState/useRef handlers here
   */
  useEffect(() => {
    const uiStateProgressCount = {
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: 0,
      [UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER]: 2,
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: 3,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: 4,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS]: 5,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS]: 6,
    };

    if (
      showTerraformationModeButton !== null &&
      uiStateProgressCount[displayUIState] <
        uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton(null);
    }

    if (
      uiStateProgressCount[displayUIState] ===
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton("normal");
    }

    if (
      uiStateProgressCount[displayUIState] >=
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]
    ) {
      setShowTerraformationModeButton("switch");
    }
  }, [displayUIState, tferState.terraformationMode]);

  useEffect(() => {
    if (tferState.FETCH_FAQS.status === STATUS_IDLE) {
      dispatchHelper(TFER_ASYNC_ACTIONS.FETCH_FAQS, getFAQs);
    }
  }, [tferState.FETCH_FAQS.status]);

  useEffect(() => {
    if (tferState.FETCH_RESOURCE_TYPES.status === STATUS_IDLE) {
      dispatchHelper(
        TFER_ASYNC_ACTIONS.FETCH_RESOURCE_TYPES,
        fetchResourceTypes,
      );
    }
  }, [tferState.FETCH_RESOURCE_TYPES.status]);

  useEffect(() => {
    if (tferState.FETCH_PROFILE.status === STATUS_IDLE) {
      dispatchHelper(TFER_ASYNC_ACTIONS.FETCH_PROFILE, fetchProfile);
    }
    if (
      tferState.FETCH_PROFILE.status === STATUS_SUCCESS &&
      tferState.FETCH_SUBDOMAINS.status === STATUS_IDLE
    ) {
      dispatchHelper(TFER_ASYNC_ACTIONS.FETCH_SUBDOMAINS, () =>
        fetchSubdomains(tferState.FETCH_PROFILE.data?.data?.customer_subdomain),
      );
    }
  }, [
    fetchSubdomains,
    tferState.FETCH_PROFILE,
    tferState.FETCH_SUBDOMAINS.status,
  ]);

  useEffect(() => {
    if (tferState.customerSubdomain !== "") {
      dispatchHelper(TFER_ASYNC_ACTIONS.FETCH_API_CREDENTIALS_STATUS, () =>
        getCustomerAPICredentialsStatus(tferState.customerSubdomain),
      );
    }
  }, [tferState.customerSubdomain]);

  useEffect(() => {
    if (tferState.FETCH_SUBDOMAINS.status !== STATUS_SUCCESS) {
      return;
    }
    if (tferState.FETCH_SUBDOMAINS.data?.length !== 1) {
      setCanAutoContinueToResources(false);
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER);
      return;
    }
    if (tferState.FETCH_API_CREDENTIALS_STATUS.status !== STATUS_SUCCESS) {
      return;
    }
    if (
      canAutoContinueToResources &&
      hasRESTAPIKey &&
      tferState.customerSubdomain !== ""
    ) {
      setCanAutoContinueToResources(false);
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER);
      return;
    }
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER);
  }, [
    tferState.FETCH_SUBDOMAINS.status,
    tferState.FETCH_API_CREDENTIALS_STATUS.status,
  ]);

  useEffect(() => {
    if (blockingErrorMessage) {
      openModalError();
    }
  }, [blockingErrorMessage]);

  useEffect(() => {
    if (displayUIState !== UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER) {
      return;
    }
    if (tferState.FETCH_RESOURCE_TYPES.status !== STATUS_SUCCESS) {
      return;
    }
    Object.keys(RESOURCES).forEach((key) => {
      const dependencies = tferState.FETCH_RESOURCE_TYPES.data[RESOURCES[key]];
      if (dependencies == null) {
        return;
      }
      for (let dep of dependencies) {
        if (
          tferState.resources[RESOURCES_KEYS_FOR_STATE[dep]].status !==
          STATUS_SUCCESS
        ) {
          return;
        }
      }
      if (tferState.resources[key].status === STATUS_IDLE) {
        dispatchGetResources(key);
      }
    });
  });

  useEffect(() => {
    const fetchAppRemoteConfig = async () => {
      const [error, data] = await getTFerAppRemoteConfig();
      if (error) {
        console.error({ error_Terraformer_fetchAppRemoteConfig: error });
        return;
      }
      setTFerAppRemoteConfig(data);
    };
    fetchAppRemoteConfig();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const subdomainRegistered = queryParams.get("subdomain_registered");
    const error = queryParams.get("error");

    if (subdomainRegistered) {
      setOauthRedirectResult("success");
      setOauthRedirectMessage(subdomainRegistered);
    }

    if (error) {
      let errMessage = "";
      try {
        errMessage = error.replace(/\+/g, " ");
      } catch (e) {
        errMessage = `Error: ${error}`;
      }
      setOauthRedirectResult("error");
      setOauthRedirectMessage(errMessage);
    }

    if (subdomainRegistered || error) {
      queryParams.delete("subdomain_registered");
      queryParams.delete("error");
      window.history.replaceState(
        { search: queryParams.toString() },
        "",
        window.location.pathname,
      );
      openModalOAuthRedirect();
    }
  }, [openModalOAuthRedirect]);

  return (
    <>
      <div id="tfer-app__container">
        <div className="tfer-app__main">
          {tferState.FETCH_FAQS.status === STATUS_SUCCESS &&
            tferState.FETCH_FAQS.data?.length > 0 && (
              <div id="tfer-app__faq" onClick={openModalFAQs}>
                Frequently Asked Questions
                <FontAwesomeIcon
                  id="PSStatus-FAQIcon"
                  icon={faQuestionCircle}
                />
              </div>
            )}
          <div id="tfer-app__title">
            <div className="tfer-app--flex tfer-app--flex-align-right">
              <h2 id="SuccessOnDemand-title">Terraformations Factory</h2>
              <span className="beta-tag">Early Access</span>
              {renderFeedbackRequestInline()}
            </div>
            {tferState.customerSubdomain && (
              <h3 className="tfer-app__customer-info">
                Company:{" "}
                <strong className="tfer-app__nowrap">
                  {tferState.FETCH_PROFILE.data?.data?.customer_company_name}
                </strong>{" "}
                / Subdomain: <strong>{tferState.customerSubdomain}</strong>
              </h3>
            )}
          </div>

          <div id="tfer-app__sub-title">
            {renderUISubtitleContent(displayUIState)}
          </div>

          <div id="tfer-app__terraformation-mode">
            <div id="tfer-app__terraformation-mode-container">
              {displayUIState !==
                UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER /* read and check if present from tferAppRemoteConfig */ && (
                <div>
                  {displayUIState >=
                    UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                    <button
                      className="tfer-app__btn"
                      title="Change customer subdomain"
                      onClick={cleanPage}
                    >
                      <FontAwesomeIcon icon={faRightLeft} />
                    </button>
                  )}
                  {(displayUIState ===
                    UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN ||
                    displayUIState ===
                      UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS ||
                    displayUIState ===
                      UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS) && (
                    <button
                      className="tfer-app__btn"
                      onClick={() => {
                        dispatch({
                          type: TFER_ACTIONS.RESET_TERRAFORMATION_PLAN,
                        });
                        setDisplayUIState(
                          UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER,
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        style={{ marginRight: 10 }}
                      />
                      Back to Resources selection
                    </button>
                  )}
                </div>
              )}

              {displayUIState === UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER && (
                <button
                  className="tfer-app__btn"
                  onClick={addNewSubdomain}
                  disabled={
                    !tferState.isDutonian ||
                    isRedirectingToPDIdentity ||
                    tferAppRemoteConfig?.["pd-identity-url"]?.value == null
                  }
                >
                  {!isRedirectingToPDIdentity ? (
                    <span>Add your account</span>
                  ) : (
                    <span style={{ fontSize: 12 }}>
                      Redirecting to PD OAuth...
                    </span>
                  )}
                </button>
              )}

              {showTerraformationModeButton === "normal" && (
                <div>
                  <button
                    className="tfer-app__btn"
                    onClick={() => {
                      displayTerraformationPlan("everything");
                    }}
                  >
                    Terraformate Everything
                  </button>
                  <button
                    className="tfer-app__btn tfer-app__btn--featured"
                    onClick={() => {
                      displayTerraformationPlan("plan");
                    }}
                  >
                    Terraformate by Plan
                  </button>
                </div>
              )}

              {showTerraformationModeButton === "switch" && (
                <div className="pills-container">
                  <ul className="pills-list">
                    <li
                      className={
                        displayUIState ===
                          UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                        tferState.terraformationMode === "everything"
                          ? "pill-list-item-active"
                          : "pill-list-item"
                      }
                    >
                      Terraformate Everything
                    </li>
                    <li
                      className={
                        displayUIState ===
                          UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                        tferState.terraformationMode === "plan"
                          ? "pill-list-item-active"
                          : "pill-list-item"
                      }
                    >
                      Terraformate by Plan
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="tfer-app__content-container">
            <div className="tabs-container">
              <div className="tfer-app__tab-content-container">
                {displayUIState === UI_PROGRESS_STATE.RUN_INITIAL_CHECKS && (
                  <ResourcesPickerSkeleton />
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER && (
                  <SubdomainPicker
                    tferState={tferState}
                    onChangeSubdomain={(v) => {
                      dispatch({
                        type: TFER_ACTIONS.SET_CUSTOMER_SUBDOMAIN,
                        data: v,
                      });
                      if (v !== "") {
                        dispatchHelper(
                          TFER_ASYNC_ACTIONS.FETCH_API_CREDENTIALS_STATUS,
                          () => getCustomerAPICredentialsStatus(v),
                        );
                        dispatch({
                          type:
                            TFER_ASYNC_ACTIONS.CREATE_API_CREDENTIALS +
                            "_RESET",
                        });
                      }
                    }}
                    onSubmit={async (apiCredential, subdomain) => {
                      if (!hasRESTAPIKey) {
                        await dispatchHelper(
                          TFER_ASYNC_ACTIONS.CREATE_API_CREDENTIALS,
                          () => createAPICredentials(apiCredential, subdomain),
                        );
                        await dispatchHelper(
                          TFER_ASYNC_ACTIONS.FETCH_API_CREDENTIALS_STATUS,
                          () => getCustomerAPICredentialsStatus(subdomain),
                        );
                      }
                      dispatch({
                        type: TFER_ACTIONS.SET_CUSTOMER_SUBDOMAIN,
                        data: subdomain,
                      });
                      if (hasRESTAPIKey) {
                        setDisplayUIState(
                          UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER,
                        );
                      }
                    }}
                  />
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                  <ResourcesPicker
                    tferState={tferState}
                    setPlan={dispatchSetTerraformationPlan}
                  />
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN && (
                  <TerraformationPlan
                    tferState={tferState}
                    requestTerraformation={requestTerraformation}
                  />
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS && (
                  <TerraformationProgress
                    terraformationRunId={terraformationRunId}
                    getTerraformationLogs={getTerraformationLogs}
                    getTerraformationStatus={getTerraformationStatus}
                    downloadTerraformCode={downloadTerraformCode}
                    tferAppRemoteConfig={tferAppRemoteConfig}
                    terraformationPlan={tferState.terraformationPlan}
                    terraformationMode={tferState.terraformationMode}
                  />
                )}
                {/* displayUIState === UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS && <></> */}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
      <>
        <Modal
          show={isModalOpen}
          title={modalTittle}
          handleClose={closeModal}
          modalFitContent
          type={modalType}
        >
          {modalDisplayContentOf === "error" && (
            <div style={{ margin: "20px" }}>
              <p>{blockingErrorMessage}</p>
              <div className="tfer-app--center-content">
                <button
                  className="OnBoarding-container-right-button button-regular"
                  onClick={closeErrorModal}
                >
                  Close and Restart
                </button>
              </div>
            </div>
          )}

          {modalDisplayContentOf === "faqs" &&
            tferState.FETCH_FAQS.data?.length > 0 && (
              <Questions questionsArray={tferState.FETCH_FAQS.data} />
            )}

          {modalDisplayContentOf === "oauth-redirect" && (
            <div style={{ margin: "20px" }}>
              {oauthRedirectResult === "success" && (
                <>
                  <p>
                    Subdomain registered:{" "}
                    <strong style={{ color: "#117170" }}>
                      {oauthRedirectMessage}
                    </strong>
                  </p>
                  <p style={{ color: "#6f6c6c" }}>
                    You should be able to see your subdomain in the list now.
                  </p>
                </>
              )}
              {oauthRedirectResult === "error" && (
                <>
                  <p style={{ color: "#b71c1c" }}>
                    An error ocurred while registering the subdomain.
                  </p>
                  <p>
                    <strong>{oauthRedirectMessage}</strong>
                  </p>
                </>
              )}
            </div>
          )}
        </Modal>
      </>
    </>
  );
}
