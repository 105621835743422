import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Button = styled.button`
  padding: 8px;
  border-radius: 8px;
  background: #048a24;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: rgb(4 59 17);
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const FilterWrapper = styled.div<{ fullWidth: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? "1 1 100%" : "1 1 calc(50% - 8px)")};
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 25px;
  align-items: center;
  justify-content: end;
`;
