import styled from "styled-components";

interface DatePickerContainerInterface {
  $haveData: boolean;
  $show: boolean;
  $size?: "Form" | "StandAlone";
}

export const DatePickerContainer = styled.div<DatePickerContainerInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${(props) => (props.$size === "Form" ? "100%" : "310px;")};
  cursor: pointer;
  color: #535353;
  & .rs-picker-input-group {
    padding: ${(props) => (props.$size === "Form" ? "9px" : "9px")};
  }
  & svg:not(.rs-picker-caret-icon.rs-icon) {
    color: #535353;
    width: 24px;
    position: absolute;
    left: 12px;
    top: ${(props) =>
      props.$size === "Form"
        ? props.$haveData
          ? "20px"
          : "17px"
        : props.$haveData
          ? "20px"
          : "17px"};
    z-index: 2;
    display: ${(props) => (props.$show ? "block" : "none")};
    pointer-events: ${(props) => (props.$haveData ? "auto" : "none")};
  }
  & * {
    outline: none !important;
  }
`;
