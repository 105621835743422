export const SERVICE_CONSULTANTS_OPTIONS = [
  "Select Consultant",
  "Akanksha Singh",
  "Alejandro Spuler",
  "Alexis Acevedo",
  "Alexis Muñoz",
  "Carmen Retamal",
  "Celeste Vega",
  "Cristóbal Maulén",
  "Dan Draper",
  "Diego Cid",
  "Dutch Rapley",
  "Eddie Willits",
  "Felipe Onituka",
  "Felipe Polhwein",
  "Gavin Reynolds",
  "Gonzalo Castillo",
  "Gonzalo Robles",
  "Ignacio Garces",
  "Jorge Spuler",
  "José Reyes",
  "Manuel Solis",
  "Patricio Cordero",
  "Raphael Souza",
  "Ricardo Hidalgo",
  "Robinson Navas",
  "Rodrigo Morales",
  "Rodrigo Muñoz",
  "Ryan Derobertis",
  "Sebastian Villanelo",
  "Tom Bryant",
  "Tony Valtinson",
];

export const TIMELINE_OPTIONS = [
  "No specific timeline",
  "1-3 Business days",
  "3-5 Business days",
  "5-7 Business days",
  "7-10 Business days",
  "10+ Business days",
];

export const REQUEST_TYPES_OPTIONS = [
  "Best Practices",
  "Consulting/Advisement",
  "Training/Enablement",
  "Team Optimization Session",
  "Services Optimization",
  "Health Check",
  "Team/User Onboarding",
  "User Offboarding",
  "Event Orchestration Migration",
  "New Integration",
  "New Solution",
  "Inactive (Ghost) User Cleanup",
  "ServiceNow Integration Assistance",
  "Salesforce Service Cloud Integration Assistance",
  "Custom Reporting",
  "PowerBI Reporting Assistance",
  "API Use",
  "New Script",
  "Data Export",
  "Terraform Assistance",
  "Technical Account Review",
  "Runbook Automation (RBA) Assistance",
  "Process Automation (PAOP) Assistance",
  "Automation Actions Assistance",
  "Bulk PagerDuty Object Creation",
  "Bulk PagerDuty Object Update",
  "Bulk PagerDuty Object Deletion",
  "Complex Schedule Creation",
  "Schedule Update Automation",
  "On-Call Compensation Assistance",
  "Slack Integration Assistance",
  "MS Teams Integration Assistance",
  "Intelligent Workflow Assistance",
  "Other"
];
