import { forwardRef, useImperativeHandle, useState } from "react";
import {
  Contenedor,
  ContenedorApps,
  ContenedorCarousel,
  ContenedorItems,
  LabelTitle,
  LicenseData,
  Licenses,
  MainCardTitle,
  ProductCard,
  RegularTitle,
  TitleBox,
} from "./ChartSliderStyled.tsx";
import UxPagination from "../UxPagination/UxPagination.js";
interface Props {
  menuItems: any;
}

const ChartSlider = forwardRef(({ menuItems }: Props, ref) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPage = 2;
  const startIndex = (currentPage - 1) * productsPage;

  useImperativeHandle(ref, () => ({}));

  return (
    <Contenedor>
        <ContenedorApps>
          <ContenedorCarousel>
            <ContenedorItems>
              {menuItems?.slice(startIndex, startIndex + productsPage).map((item: any) => (
                 <ProductCard key={item.id}> {/* Ensure a unique key */}
                  <TitleBox>
                    <LabelTitle>
                      {item?.licence_type === "full_user" ? (
                        <h3>Full User</h3>
                      ) : item?.licence_type === "read_only" ? (
                        <h3>Stakeholder</h3>
                      ) : (
                        <h3>{item?.licence_type}</h3>
                      )}
                    </LabelTitle>
                    <MainCardTitle>{item?.customer_product_name}</MainCardTitle>
                  </TitleBox>
                  <Licenses>
                    <LicenseData>
                      <RegularTitle 
                        className={`digits-${item?.customer_purchased_licenses?.toString().length}`}>
                        {item?.customer_purchased_licenses?.toLocaleString()}
                      </RegularTitle>
                      <p>PURCHASED</p>
                    </LicenseData>
                    <LicenseData>
                      <RegularTitle 
                        className={`digits-${item?.customer_deployed_licenses?.toString().length}`}>
                        {item?.customer_deployed_licenses?.toLocaleString()}
                      </RegularTitle>
                        <p>USED</p>
                    </LicenseData>
                    <LicenseData>
                      <RegularTitle 
                          className={`digits-${item?.customer_available_licenses?.toString().length}`}>
                        {item?.customer_available_licenses?.toLocaleString()}
                      </RegularTitle>
                      <p>AVAILABLE</p>
                    </LicenseData>
                  </Licenses>
                </ProductCard>              
                ))}
            </ContenedorItems>
          </ContenedorCarousel>
          <UxPagination
            productsPage={productsPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalProducts={menuItems.length}
          />
        </ContenedorApps>
    </Contenedor>
  );
});

export default ChartSlider;