import React, { useState, useEffect } from 'react';
import   Modal  from "../../../components/Modal";
import { Button, DatePicker, Icon, PDInput, Tiptap } from '@/components';
import {
  ResourceContainer,
  ResourceTitle,
  ContainerLink,
  GhostButton,
  ReleaseStyle,
  ButtonNewReleaseSve,
  ReleaseTitle,
  Select,
  FlexContainer,
  ColumnContainer
} from '../LearningHub.styled';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { apiRequest, apiRoutes } from '../../../services';


interface Resource {
  file_name: string;
  link: string;
  release_id: number
}

interface ReleaseApplication {
  content: string;
  description: string;
  applicationId: number;
  releaseApplicationResources: {
    release_application_id: number;
    file_name: string;
    link: string;
  }[];
}

interface ReleaseData {
  date: string;
  content: string;
  description: string;
  releaseResources: Resource[];
  releaseMonthId: number;
}

const ModalNewRelease: React.FC<Props> = ({showModalRelease, setShowModalRelease, releaseMonth, getRelease}) => {
  const [releaseDate, setReleaseDate] = useState<string>('');
  const [releaseTitle, setReleaseTitle] = useState<string>('');
  const [releaseDescription, setReleaseDescription] = useState<string>('');
  const [resources, setResources] = useState<Resource[]>([
    { file_name: '', link: '', release_id: 0 }
  ]);
  const [selectedMonthId, setSelectedMonthId] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    const validateForm = () => {
      if (!releaseDate || !releaseTitle || !releaseDescription || !selectedMonthId) {
        setIsValid(false);
        return;
      }

      const areResourcesValid = resources.every(
        resource => resource.file_name.trim() !== '' && resource.link.trim() !== ''
      );

      setIsValid(areResourcesValid);
    };

    validateForm();
  }, [releaseDate, releaseTitle, releaseDescription, selectedMonthId, resources]);


  const addResource = () => {
    setResources([...resources, { file_name: '', link: '', release_id: 0 }]);
  };

  const removeResource = (index: number) => {
    const newResources = resources.filter((_, i) => i !== index);
    setResources(newResources);
  };

  const updateResource = (index: number, field: keyof Resource, value: string) => {
    const newResources = resources.map((resource, i) => {
      if (i === index) {
        return { ...resource, [field]: value };
      }
      return resource;
    });
    setResources(newResources);
  };


  const handleSubmit = async () => {
    const releaseData: ReleaseData = {
      date: formatDate(releaseDate),
      content: releaseTitle,
      description: releaseDescription,
      releaseResources: resources,
      releaseMonthId: selectedMonthId,
    };


    try {
      const [error, data] = await apiRequest({
        method: 'post',
        url: apiRoutes.releasedataUpdate,
        data: releaseData
      });
  

      if (data) {
        getRelease && getRelease()
        setShowModalRelease(false);
        setIsValid(false);
        
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

 

  const formatDate = (date: any): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const  isFormValid = (): boolean => {
    // Validar campos principales
    if (!releaseDate || !releaseTitle || !releaseDescription || !selectedMonthId) {
      return false;
    }
  }

  return(
    <Modal
      show={showModalRelease}
      handleClose={() => setShowModalRelease(false)}
      title={"Add New Release"}
    >
      <>
        <ReleaseTitle>Release Date</ReleaseTitle>
        <FlexContainer>
          <DatePicker
            value={releaseDate}
            onChange={(value) => setReleaseDate(value)}
          />
          <Select 
            value={selectedMonthId}
            onChange={(e) => setSelectedMonthId(Number(e.target.value))}
            style={{
              width: '100%',
              padding: '8px',
              marginTop: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
            >
            {releaseMonth.map((month: any) => (
              <option key={month.id} value={month.id}>
                {month.month}
              </option>
            ))}
          </Select>
        </FlexContainer>
        <ReleaseTitle>Title release</ReleaseTitle>
          <PDInput
            value={releaseTitle}
            onChange={(value) => setReleaseTitle(value)}
          />
          
          <Tiptap
            onChange={(value) => setReleaseDescription(value)}
            initialContent={releaseDescription}
          />
        <ReleaseStyle>
          <ContainerLink>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <ResourceTitle>Resources</ResourceTitle>
              <div>
                <GhostButton
                  onClick={addResource}
                  $variant='bordered'
                >
                  Add Resource{' '}
                  <PlusIcon width={24} height={24} />
                </GhostButton>
              </div>
            </div>

            {resources.map((resource, index) => (
              <ResourceContainer key={index}>
                <PDInput
                  value={resource.file_name}
                  onChange={(newValue) => updateResource(index, 'file_name', newValue)}
                  placeholder="Resource Name"
                />
                <PDInput
                  value={resource.link}
                  onChange={(newValue) => updateResource(index, 'link', newValue)}
                  placeholder="Resource Link"
                />
                <GhostButton
                  onClick={() => removeResource(index)}
                  disabled={resources.length === 1}
                >
                  <TrashIcon width={24} height={24} />
                </GhostButton>
              </ResourceContainer>
            ))}
          </ContainerLink>
        </ReleaseStyle>
        <ButtonNewReleaseSve>
          <Button
            onClick={handleSubmit}
            type='primary'
            text='Save'
            icon='CheckIcon'
            disabled={!isValid}
          />
        </ButtonNewReleaseSve>
      </>
    </Modal>
  );
};

export default ModalNewRelease;