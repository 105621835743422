import React, { useState, useEffect, useRef } from "react";
import GenericTable from "../../components/GenericTable/GenericTable";

const PremiumRequestTable = ({
  projects,
  totalProjects,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
}) => {
  const tableRef = useRef(null);

  const titles = [
    {
      label: "Account Name",
      widthPX: 150,
      rowKey: "client_name",
      type: "String",
    },
    {
      label: "Case Number",
      widthPX: 150,
      rowKey: "case_number",
      type: "String",
    },
    {
      label: "Case Subject",
      widthPX: 200,
      rowKey: "task_name",
      type: "String",
    },
    {
      label: "Hours Used",
      filtrable: true,
      widthPX: 150,
      rowKey: "hours_used",
      type: "String",
    },
    {
      label: "Owner",
      filtrable: true,
      widthPX: 250,
      rowKey: "task_collaborator",
      type: "String",
    },
    {
      label: "Status",
      sortable: true,
      widthPX: 200,
      rowKey: "task_status",
      type: "String",
    },
    {
      label: "Creation Date",
      widthPX: 200,
      rowKey: "task_created",
      type: "Date",
    },
  ];

  return (
    <div className="premium-request-table">
      <GenericTable
        ref={tableRef}
        title=""
        columnsArray={titles}
        rowsArray={projects}
        showElementsPerPage={itemsPerPage}
        isLoading={false}
        filterDateKey="task_created"
        onPaginationChange={setCurrentPage}
        actualPage={currentPage}
        setActualPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItems={totalProjects}
      />
    </div>
  );
};

export default PremiumRequestTable;
